import { Directive, OnInit, inject, DestroyRef, Host } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { Location } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationExtrasState } from '../../../../../types/angular';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[routerLink][returnUrl], [selectedMarinaRouterLink][returnUrl]',
})
export class RouterLinkReturnUrlDirective implements OnInit {
  #destroyRef = inject(DestroyRef);
  #location = inject(Location);
  #aRoute = inject(ActivatedRoute);

  constructor(@Host() private routerLink: RouterLink) {}

  ngOnInit(): void {
    if (!this.routerLink) {
      return;
    }
    this.#aRoute.queryParams.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe(() => {
      const backLinkState = (this.#location.getState() as NavigationExtrasState)?.returnUrl;
      if (!backLinkState) {
        return;
      }
      const splitParams = backLinkState.split('?');
      this.routerLink.routerLink = splitParams[0];
      if (splitParams[1]) {
        const queryParams: Record<string, unknown> = {};
        new URLSearchParams(splitParams[1]).forEach((value, key) => {
          queryParams[key] = value;
        });
        this.routerLink.queryParams = queryParams;
      }
    });
  }
}
