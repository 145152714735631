<!-- eslint-disable @angular-eslint/template/no-interpolation-in-attributes -->
<div class="flag-button-wrapper d-flex align-items-center">
  <div class="select-form">
    <ng-select
      class="phone"
      (ngModelChange)="onDialCodeChange($event)"
      [clearable]="false"
      notFoundText="{{ 'NOT_FOUND' | translate }}"
      [(ngModel)]="selectedCountry"
      [searchFn]="customSearchFn"
      [disabled]="isDisabled()"
      dmFormSelectPhoneCountry
    >
      <ng-template ng-label-tmp ng-option-tmp let-country="item">
        <div class="d-flex align-items-center">
          <img [src]="country.code.split('-')[1] || country.code | uppercase | flag: 20" alt="flag" class="me-1" />
          <span class="dial-code text-secondary fw-500">{{ country.dial_code }}</span>
        </div>
      </ng-template>
    </ng-select>
    <div class="separator"></div>
  </div>

  <div class="phone-form">
    <input
      id="phone"
      class="phone-form__input"
      type="phone"
      placeholder="{{ 'PHONE' | translate | titlecase }}"
      max="99999999999999"
      min="0"
      oninput="this.value = this.value.slice(0, 14)"
      [disabled]="isDisabled()"
      [(ngModel)]="phoneNumber"
      (ngModelChange)="onPhoneNumberChange($event)"
    />
  </div>
</div>
