import {
  BerthStatus,
  BoatType,
  Currency,
  CustomerType,
  OrderPaymentStatus,
  PaymentMethodType,
  QuotationStatus,
  ResourceType,
  UserType,
} from '../backend';
import { ICustomerShort } from './customer.interface';
import { CrmBookingStatus } from './resource-booking.interface';
import { TimestampRangeSerialized } from '@dm-workspace/shared';
import { MarinaProduct } from './marina.interface';
import { BillingType } from './products.interface';
import { OrderMarinaProduct, OrderProductType } from './order.interface';
import { NumberRangeSerialized } from '../../../../shared/src/lib/number-range';
import { YamlValue } from './yaml.interface';
import { CountryCode, LOCALIZATION_LANGUAGES } from '../constants';
import { IBoat } from './boats.interface';
import { ObReservationChannel } from './online-booking';

export type MmsQuotesStatName = 'waiting' | 'new' | 'sent' | 'accepted' | 'declined' | 'all' | 'readyToSend';
export type MmsQuotesStats = Record<MmsQuotesStatName, number>;

export interface Quote {
  quoteId: string;
  isInCrm: boolean;
  versionId: string;
  ticket: IQuoteTicket;
  startDate: string;
  channel: string;
  endDate: string;
  isCurrentVersion: boolean;
  quoteStatus: QuoteStatus;
  type: QuoteType;
  expirationDate: string;
  proposedResource?: QuoteProposedResource;
  revisionVersion: number;
  customer: ICustomerShort;
  name: string;
  order: QuoteOrder | null;
  currency: Currency;
  utilitiesBillingType: BillingType;
  marinaCode: string;
  updatedAt: string;
  boat?: QuoteBoat;
  vatIncluded: boolean;
  createdAt: string;
  actions: QuoteAction[];
  length: number;
  beam: number;
  netTonnage: number;
  paymentTerm: IQuotePaymentTerm;
  overridePaymentMethods: PaymentMethodType[];
  paymentMethod: IQuotePaymentMethod;
  crmStatus: QuoteCrmStatus;
  crmState: QuoteCrmState;
  totalPrice: number;
  totalPriceGross: number;
  booking: QuoteBooking | null;
  products?: QuoteProduct[];
  promotionalOffers: QuotePromotionalOffers[] | null;
  serviceType: QuoteServiceType;
  leadSource: QuoteLeadSource;
  waitingListValidUntil: string;
  declinedAt: string; // TODO post MVP
  activitySummary: QuoteActivitySummary;
  description: YamlValue<QuoteDescription | null>;
  descriptionParsed: {
    boat?: MmsQuoteDescriptionBoat;
    person?: MmsQuoteDescriptionCustomer;
    channel?: ObReservationChannel;
  } | null;
}
export interface AvailableBookableResourceForQuote {
  quoteId: string;
  bookableResource: BookableResource;
}
export interface BookableResource {
  id: string;
  marina: string;
  name: string;
  pier: string;
  pierId: string;
  type: ResourceType;
  berthIdArray: string[];
  length: number;
  width: number;
  depth: string;
  minAllowedBoatLength: number;
  minAllowedBoatWidth: number;
  maxAllowedBoatLength: number;
  maxAllowedBoatWidth: number;
  area: number;
  berthStatus: BerthStatus;
  onlineBookingEnabled: boolean;
  customerPanelEnabled: boolean;
}
export type QuoteDescriptionChannel = 'D-Marin Website' | 'Mobile' | 'Phone';

export interface QuoteDescription {
  Channel: QuoteDescriptionChannel;
  boat: Record<string, string>;
  person: Record<string, string>;
}

export interface QuoteActivitySummary {
  quoteId: string;
  contactAttempts: QuoteContactAttempt;
  createdAt: string | null;
  firstSentAt: string | null;
  lastSentAt: string | null;
  acceptedAt: string | null;
  declinedAt: string | null;
  lastSeenAt: string | null;
}

export interface QuoteContactAttempt {
  count: number | null;
  last: string | null;
}

export type QuoteAction =
  | 'SEND_OFFER'
  | 'RESEND_OFFER'
  | 'SEND_PAYMENT_REMINDER'
  | 'REVISE_OFFER'
  | 'MOVE_TO_THE_WAITING_LIST'
  | 'CHANGE_VALIDITY'
  | 'MARK_AS_PAID'
  | 'MARK_AS_ACCEPTED'
  | 'SEND_REMINDER'
  | 'ACCEPT_OFFER'
  | 'DECLINE_OFFER'
  | 'EDIT'
  | 'CANCEL';

export enum QuoteLeadSource {
  MOBILE_APP = 'MOBILE',
  PHONE = 'PHONE',
  WEB = 'WEB',
  EMAIL = 'E_MAIL',
  WALK_IN_OR_OFFICE = 'WALK-IN/OFFICE',
  RADIO = 'RADIO_(VHF)',
  CRM = 'CRM',
}

export type QuoteOrder = {
  paymentStatus: OrderPaymentStatus;
  id: string;
  crmPaymentStatus: CrmPaymentStatus;
};

export type CrmPaymentStatus = 'PAID' | 'NOT_PAID' | 'PARTIALLY_PAID' | 'CANCELLED';

export type QuoteBooking = {
  id: string;
  fromDate: string;
  toDate: string;
  crmStatus: CrmBookingStatus;
  orderProductId?: string;
  contractId?: string;
  humanReadableId: string;
  quotationStatus: QuotationStatus;
  source?: string;
};

export type QuoteAcceptResponse = {
  order: {
    orderId: string;
    checkId: string;
  };
};

export type QuoteDetails = Quote & {
  data: QuoteProduct[];
  actualHomeMarina?: string;
  /**
   * @deprecated
   */
  cmsPortalInfo: {
    data: QuoteDetailsCmsData[];
  };
  activitySummary: QuoteActivitiesSummary;
};

export type QuoteDetailsCmsData = {
  id: number;
  channel: string;
  attributes: {
    marinaOffer: CmsContent[];
    marinaSurroundings: CmsContent[];
    benefits: QuoteDetailsCmsDataBenefit[];
  };
};

export type CmsContent = CmsContentHeading | CmsContentParagraph | CmsContentList | CmsChildText | CmsContentListItem;

export type CmsContentHeading = {
  type: 'heading';
  level: number;
  children: CmsContent[];
};

export type CmsContentParagraph = {
  type: 'paragraph';
  children: CmsContent[];
};

export type CmsChildText = {
  text: string;
  type: 'text';
  bold: boolean;
};

export type CmsContentList = {
  format: 'unordered' | 'ordered';
  type: 'list';
  children: CmsContentListItem[];
};

export type CmsContentListItem = {
  type: 'list-item';
  children: CmsContent[];
};

export type QuoteDetailsCmsDataBenefit = {
  title: string;
  imageLocation: string;
  id: number;
  description: CmsContent[];
  image: {
    data: QuoteDetailsCmsDataBenefitImageData[];
  };
};

export type QuoteDetailsCmsDataBenefitImageData = {
  attributes: {
    name: string;
    alternativeText: string;
    caption: string;
    width: number;
    height: number;
    formats: unknown;
    hash: string;
    ext: string;
    mime: string;
    size: number;
    url: string;
    previewUrl: string;
    provider: string;
    provider_metadata: string;
    createdAt: string;
    updatedAt: string;
    placeholder: string;
  };
  id: number;
};

export type QuoteActivitiesSummary = {
  offerAttempts: number;
  contactAttempts: {
    count: number;
    last: string;
  };
  createdAt: string | null;
  firstSentAt: string | null;
  lastSentAt: string | null;
  acceptedAt: string | null;
  declinedAt: string | null;
  lastSeenAt: string | null;
};
export type QuoteDetailsCms = {
  berth?: QuoteDetailsCmsItem;
  mooring?: QuoteDetailsCmsItem;
  sensors?: QuoteDetailsCmsItem;
};

export type QuoteDetailsCmsItem = {
  description: string;
  image: string;
  title: string;
  richTextDescription?: CmsBlock[];
};

export type CmsBlock = CmsBlockRichText | CmsBlockImage;

export type CmsBlockRichText = {
  type: 'RichText';
  value: string;
};

export type CmsBlockImage = {
  type: 'Image';
  value: string;
  kind: 'left' | 'right';
};

export interface QuoteUpsertDto extends CalculateQuotePricesDto {
  customerId: string;
  saveAsDraft: boolean;
  putOnWaitingList: boolean;
  leadSource: string;
  promotionalOffers: null | QuotePromotionalOffers[];
  paymentMethods: PaymentMethodType[];
  validTo: string;
  channel: string;
  actualHomeMarina?: string;
  processInCrm: boolean;
}
export interface QuoteRevisionDto {
  id: string;
  startDate: string;
  endDate: string;
  currency: Currency;
  isCurrentVersion: boolean;
  overridePaymentMethods: PaymentMethodType;
  quoteStatus: QuoteStatus;
  type: QuoteType;
  revisionVersion: number;
  customer: {
    displayName: string;
    id: string;
    name: string;
    firstName: string;
    lastName: string;
    email: string;
    countryCode: CountryCode;
    type: CustomerType;
    phone: string;
    isActive: boolean;
    accNumber: string;
  };
  name: string;
  boat: IBoat;
  totalPrice: number;
  totalPriceGross: number;
  products: QuoteRevisionDtoOrderProduct[];
  utilitiesBillingType: BillingType;
}
export type QuoteRevisionDtoOrderProduct = QuotePricingCalculationItemDto & {
  product: OrderMarinaProduct;
};
export interface QuoteProductDiscountDto {
  product: OrderProductType;
  discountPercentage: number;
}

export interface CalculateQuotePricesDto {
  boatId: string;
  startDate: string;
  endDate: string;
  products: QuoteMooringProductDto[];
  discounts: QuoteProductDiscountDto[];
}
export interface QuotePricingCalculationDto {
  items: QuotePricingCalculationItemDto[];
}
export interface QuotePricingCalculationItemDto {
  product: OrderProductType;
  billingType: BillingType;
  currency: Currency;
  discountName: string;
  discountPercent: number;
  discountAmount: number;
  quantity: number;
  unit: string;
  pricePerUnit: number;
  totalPrice: number;
  vatAmount: number;
  vatRate: number;
}

export interface QuoteMooringProductDto {
  product: OrderProductType;
  resourceId: string;
  utilitiesBillingType: BillingType | null;
}
export type QuoteProductDto = QuoteMooringProductDto;
export type CreateQuoteBody = {
  proposedResourceId?: string | null;
  name: string;
  boatId?: string | null;
  startDate: string;
  endDate: string;
  paymentMethodId: string | null;
  paymentTermId: string | null;
  contactId: string;
  products: ICreateQuoteProductList;
  length: number | null;
  beam: number | null;
  netTonnage: number | null;
};

export type EditQuoteBody = CreateQuoteBody;

export type CancelDeclineQuoteBody = {
  reason: QuoteCancelReason | QuoteDeclineReason | QuoteCancelReasonExtended | string;
  shouldInformCustomer?: boolean;
};

export type MmsOfferSendTarget = 'NATIVE_APP' | 'WEB';

export type GetQuotesParams = {
  marinaCode?: string;
  customerId?: string;
  nameFragment?: string;
  contactId?: string;
  boatId?: string;
  quoteStatus?: QuoteStatus | QuoteStatus[];
  paymentStatus?: QuotesPaymentStatus;
  type?: QuoteType[] | QuoteType;
  crmStatus?: QuoteCrmStatus;
  crmState?: QuoteCrmState;
  sentAt?: TimestampRangeSerialized;
  acceptedAt?: TimestampRangeSerialized;
  createdAt?: TimestampRangeSerialized;
  declinedAt?: TimestampRangeSerialized;
  lastSentAt?: TimestampRangeSerialized;
  waitingListValidUntil?: string;
  totalAmount?: NumberRangeSerialized;
  showOnlyWithResource?: boolean;
  serviceType?: QuoteServiceType;
  customerType?: QuoteCustomerType;
};

export enum MarinaTypes {
  EXTERNAL = 'EXTERNAL',
  INTERNAL = 'INTERNAL',
  ALL = 'ALL',
}

export type QuoteExportParams = {
  dateFrom: string;
  dateTo: string;
  marinaType: MarinaTypes;
};

export enum QuoteCustomerType {
  INDIVIDUAL = 'Individual',
  CORPORATE_INDIVIDUAL = 'CorporateIndividual',
  CHARTER = 'Charter',
  AGENT = 'Agent',
  BROKER = 'Broker',
}

export enum QuoteServiceType {
  CRANE_PASS = 'CranePass',
  DRY_DOCK = 'DryDock',
  MOORING = 'Mooring',
  MOORING_CONCESSION = 'MooringConcession',
  HOSPITALITY = 'Hospitality',
  RENT_FB = 'RentFB',
  RENT_GARAGE = 'RentGarage',
  RENT_OFFICE = 'RentOffice',
  RENT_OTHER = 'RentOther',
  RENT_SHOP = 'RentShop',
  RENT_STORAGE = 'RentStorage',
  RENT_TECHNICAL = 'RentTechnical',
}

export enum QuotesPaymentStatus {
  PAID = 'PAID',
  WAITING = 'WAITING',
  UNPAID = 'UNPAID',
  UNKNOWN = 'UNKNOWN',
  // REJECTED = 'REJECTED',
}

export interface ICreateQuoteProductList {
  add: IAddQuoteProduct[];
  modify: IModifyQuoteProduct[];
  delete: IDeleteQuoteProduct[];
}

export interface IQuoteTicket {
  id: string;
  lastMessageId: string;
}

export enum QuoteStatus {
  NEW = 'NEW',
  WAITING_LIST = 'WAITING_LIST',
  PENDING_FOR_APPROVAL = 'PENDING_FOR_APPROVAL',
  DRAFT = 'DRAFT',
  READY_TO_SEND = 'READY_TO_SEND',
  SENT = 'SENT',
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  REVISED = 'REVISED',
  EXPIRED = 'EXPIRED',
  CANCELLED = 'CANCELLED',
  AWAITS_AUTO_APPROVAL = 'AWAITS_AUTO_APPROVAL',
}

export enum QuoteType {
  ANNUAL_SALES = 'Annual Sales',
  MID_TERM = 'Mid Term',
  TRANSIENT = 'Transient',
  OTHER_SERVICES = 'Other Services',
  RENEWAL = 'Renewal',
  QUICK_QUOTE = 'Quick Quote',
}

export interface QuoteUser {
  id: string;
  name: string;
  type: UserType;
}

export interface QuoteProposedResource {
  id: string;
  berthIds: string[];
  name: string;
  disabled: boolean;
  resourceId?: string;
  type: ResourceType;
}

export interface QuoteProduct {
  product: MarinaProduct;
  quantity: number;
  discountName: string;
  discountAmount: number;
  discountPercent: number;
  pricePerUnit: number;
  totalPrice: number;
  totalPriceGross: number;
  unit: string;
  vatAmount: number;
  vatRate: number;
}

export interface ICreateQuoteProduct {
  resourceId?: string | null;
  quantity: number;
  discountName: string | null;
  discountAmount: number | null;
  unit: string;
  pricePerUnit: number;
}

export interface IAddQuoteProduct extends ICreateQuoteProduct {
  productId: string;
}

export interface IModifyQuoteProduct extends ICreateQuoteProduct {
  id: string;
}

export interface IDeleteQuoteProduct {
  id: string;
}

export interface QuoteBoat {
  name: string;
  id: string;
  type: BoatType;
  flag: string;
  registrationNumber: string;
  length: number;
  maxBeam: number;
}

export interface IQuotePaymentTerm {
  id: string;
  name: string;
  description: string;
  isDefaultTermForMmsBooking: boolean;
}

export interface IQuotePaymentMethod {
  id: string;
  name: string;
  description: string;
  isDefaultMethodForMmsBooking: boolean;
}

export enum QuoteCrmStatus {
  IN_PROGRESS = 'In Progress',
  PENDING_FOR_APPROVAL = 'Pending for approval',
  APPROVED = 'Approved',
  DENIED = 'Denied',
  WON = 'Won',
  LOST = 'Lost',
  CANCELED = 'Canceled',
  REVISED = 'Revised',
}
export enum QuotePromotionalOffers {
  HAPPY_BERTH_DAYS = 'HAPPY_BERTH_DAYS',
}
export enum QuoteCrmState {
  DRAFT = 'Draft',
  ACTIVE = 'Active',
  WON = 'Won',
  CLOSED = 'Closed',
}

export enum QuoteCancelReason {
  PROCESS_THROUGH_CRM_DUE_TO_TECHNICAL_REASONS = 'Process through CRM due to technical reasons',
  REVISE_INITIAL_QUOTE_DUE_TO_USER_ERROR = 'Revise Initial quote due to user error',
  REVISE_INITIAL_QUOTE_DUE_TO_CHANGE_OFFER = 'Revise Initial quote due to change offer',
  NO_PAYMENT_RECEIVED_FOR_THE_OFFER = 'No payment received for the offer',
  OFFER_PAYMENT_EXPIRED = 'Offer / payment expired',
}

export enum QuoteCancelReasonExtended {
  SWITCHED_TO_QQ = 'Edited enquiry switched to QQ',
}

export enum QuoteDeclineReason {
  NO_AVAILABILITY = 'No availability',
  NO_PAYMENT_RECEIVED_FOR_THE_OFFER = 'No payment received for the offer',
  CHANGE_CRUISING_GROUNDS = 'Change cruising grounds',
  BOAT_SELL_RESELL_SAME_BERTH = 'Boat sell. Resell same berth',
  BOAT_SELL_BOAT_DEPART = 'Boat sell. Boat depart',
  OWNER_DEATH = 'Owner deaths',
  CHANGE_TO_MIDTERM = 'Change to midterm',
  CHANGE_TO_TRANSIENTS = 'Change to transients',
  IMPROPER_BEHAVIOR_AGAINST_MARINA_RULES = 'Improper behavior against marina rules',
}

export type MmsQuoteChangeValidUntilParams = {
  waitingListValidUntil: string;
};

export type MmsQuoteMoveToWaitingListParams = {
  waitingListValidUntil: string;
  notifyUser: boolean;
  reason?: string;
};

export type MmsQuoteDescriptionBoat = {
  width?: number;
  length?: number;
  draft?: number;
  name?: string;
  registrationNumber?: string;
  portOfRegistration?: string;
  type?: BoatType;
  flag?: string;
  isCharter?: boolean;
  brand?: string;
};

export type MmsQuoteDescriptionCustomer = {
  firstName?: string | null;
  lastName?: string | null;
  country?: CountryCode | null;
  stateId?: string | null;
  city?: string | null;
  address?: string | null;
  zip?: string | null;
  language?: LOCALIZATION_LANGUAGES | null;
  email?: string | null;
  phone?: string | null;
  type?: CustomerType;
  companyName?: string | null;
  passport: string | null;
  fiscalCode: string | null;
  taxExemptNumber: string | null;
  countyId: string | null;
};

export type MmsQuoteActivity = {
  data: MmsQuoteActivityData;
  quoteId: string;
  revisionNumber: number;
  createdAt: string;
  type: MmsQuoteActivityType;
  author: MmsQuoteActivityAuthor;
};

export type MmsQuoteActivityType =
  | 'CREATED'
  | 'UPDATED'
  | 'NEW_REVISION'
  | 'SENT'
  | 'REMINDER_SENT'
  | 'PAYMENT_REMINDER_SENT'
  | 'ACCEPTED'
  | 'CANCELLED'
  | 'DECLINED'
  | 'LOST'
  | 'MARKED_AS_PAID'
  | 'PUTTED_ON_WAITING_LIST'
  | 'VALID_UNTIL_CHANGED'
  | 'NOTE'
  | 'NOTE_WITH_CONTACT'
  | 'SEEN';

export type MmsQuoteActivityAuthor = {
  id: string;
  name: string;
  email: string;
  type: string;
};

export type MmsQuoteActivityData = {
  content: string;
  asLastContact: boolean;
};

export type MmsQuoteNoteBody = {
  content: string;
  asLastContact: boolean;
};
