import { BoatType, Currency, ResourceType } from '../backend';
import { MarinaProductUnit, OrderProductType } from './order.interface';
import { BillingType } from './products.interface';
import { PylonsStatusInMarina } from './berth.interface';

export enum MooringSpotStatus {
  PYLON_OUTPUT_NOT_FOUND = 'PYLON_OUTPUT_NOT_FOUND',
  PYLON_OUTPUT_NOT_ACTIVE = 'PYLON_OUTPUT_NOT_ACTIVE',
  PYLON_OUTPUT_ALREADY_IN_USE = 'PYLON_OUTPUT_ALREADY_IN_USE',
  PYLON_OUTPUT_AVAILABLE = 'PYLON_OUTPUT_AVAILABLE',
}

export enum MooringPaymentStatus {
  PYLON_NOT_AVAILABLE = 'PYLON_NOT_AVAILABLE',
  PYLON_PAYMENT_REQUIRED = 'PYLON_PAYMENT_REQUIRED',
  PYLON_PURCHASE_NOT_FOUND = 'PYLON_PURCHASE_NOT_FOUND',
  PYLON_ENABLED = 'PYLON_ENABLED',
}

export interface IMooringSpot {
  status: MooringSpotStatus;
}

export interface IMooringConfirmChoiceResponse {
  status: MooringPaymentStatus;
  purchaseProducts: IMooringSpotPurchaseProduct[];
}

export interface IMooringSpotPurchaseProduct {
  id: string;
  amount: number;
  unit: string;
  price: number;
  currency: Currency;
}

export interface IMarinaPylonsResponse {
  pylonCode: string;
  pylonName: string;
  outputName: string;
  mediaType: PylonMediaType;
  isUsed: boolean;
  unplugDetection: boolean;
  bookingReferenceId: string | null; // bookingHumanReadableId: string;
  boat: IMarinaPylonBoat;
  resource: IMarinaPylonResource;
  currentConsumption: number;
  mediaUsage: IMediaUsage;
}

export enum PylonMediaType {
  ELECTRICITY = 'electricity',
  WATER = 'water',
}

export interface IMediaUsage {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  product: any;
  currency: Currency;
  quantity: number;
  price: number;
  unit: MarinaProductUnit;
}

export interface IMarinaPylonBoat {
  name: string;
  id: string;
  type: BoatType;
  flag: string;
  registrationNumber: string;
  length: number;
  maxBeam: number;
}

export interface IMarinaPylonResource {
  id: string;
  resourceId: string;
  berthIds: string[];
  name: string | null;
  type: ResourceType;
}

export interface IMarinaPylonsResponseSearch extends Omit<Partial<IMarinaPylonsResponse>, 'boat' | 'resource'> {
  boatId?: string;
  resourceId?: string;
}

export interface PylonSocketSwitchStateBody {
  referenceId: string;
  state: boolean;
}

export interface IObPylonLocation {
  marinaCode: string;
  pierName: string;
}

export type ObPylonParams = {
  referenceId: string;
  email: string;
};

export type PylonSocket = {
  channel: string;
  mediaType: PylonMediaType;
  isAvailable: boolean;
  isUsed: boolean;
};

export interface PylonsMapResponseDto {
  bookings: unknown[];
  pylons: ListMarinaPylonsResponseDto[];
}
export interface ListMarinaPylonsResponseDto {
  pylonCode: string;
  pylonName: string;
  location: string;
  outputName: string;
  mediaType: 'electricity' | 'water';
  isUsed: boolean;
  pylonBillingType: BillingType;
  unplugDetection: boolean;
  bookingReferenceId?: string;
  currentConsumption?: string;
  mediaUsage?: ListMarinaPylonsMediaUsage;
  boat?: ListMarinaPylonsBoat;
  resource?: ListMarinaPylonsResource;
}
export interface ListMarinaPylonsMediaUsage {
  product: OrderProductType;
  quantity: string;
  unit: string;
  price: number;
  currency: string;
}
export interface ListMarinaPylonsBoat {
  flag: string;
  name: string;
  id: string;
  type: BoatType;
  registrationNumber: string;
  length: number;
  maxBeam: number;
  isActive: boolean;
  vssNumber: string;
}
export interface ListMarinaPylonsResource {
  id: string;
  type: string;
  resourceId: string;
  berthIds: string[];
  name: string;
}

export interface GroupedPylons {
  name: string;
  pylons: ListMarinaPylonsResponseDto[];
  location: google.maps.LatLngLiteral;
  status: PylonsStatusInMarina;
}
export type TPylonState = 'ON' | 'OFF';
export type TPylonAction = 'socketState' | 'unplugDetection' | 'block';
