import { Customer, ICountySimple } from './customer.interface';
import { ContactMethod, ContactType } from '../backend';
import { Gender } from './common.interface';
import { IPreferences } from './preferences.interface';
import { CountryCode, LOCALIZATION_LANGUAGES } from '../constants';

export interface Contact {
  id: string;
  type: ContactType;
  firstName: string;
  localFirstName: string;
  lastName: string;
  localLastName: string;
  hasAccount: boolean;
  address: string;
  city: string;
  customerId: string;
  state: string;
  stateOrProvince: ICountySimple;
  zip: string;
  country: string;
  countryCode?: CountryCode;
  email: string;
  phone: string;
  fax: string;
  language: LOCALIZATION_LANGUAGES;
  birthday: string;
  gender: Gender;
  nationality: string;
  passport: string;
  drivingLicense: string;
  profession: string;
  taxExemptNumber: string;
  preferences: IPreferences;
  countryTaxReference: string;
  contactMethod: ContactMethod;
  allowedContactMethod: IAllowedContactMethod;
  marketingMaterials: boolean;
  marketingOnlyInterest: boolean;
  marketingAllowTracking: boolean;
  customer: Customer;
  county?: ICountySimple;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parent: any;
}
export interface IAllowedContactMethod {
  email: boolean;
  followingEmail: boolean;
  bulkEmail: boolean;
  phone: boolean;
  fax: boolean;
  mail: boolean;
}

export type EditContactDto = {
  ignoreDuplicateEmail?: boolean;
  makePrimary?: boolean;
  contact: EditContactDetailsDto;
};

export type EditContactDetailsDto = {
  countryCode?: CountryCode;
  customerId: string;
  type: ContactType;
  language: string;
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  zip: string;
  email: string;
  phone: string;
  contactMethod?: ContactMethod;
};

export type MmsContact = Omit<Contact, 'customer'> & {
  user?: MmsCustomerContactUser;
};

export type MmsCustomerContactUser = {
  id: string;
  name: string;
  type: string;
  email: string;
  createdAt: string;
  contactId: string;
  preferredLanguage: string;
  isActive: boolean;
};

export interface ContactDto {
  firstName: string;
  customerId: string;
}
