<div class="d-flex justify-content-start align-items-start">
  <p>
    <span class="i-arrow-x"></span>
    {{ "LENGTH" | translate }}:&nbsp;
    <strong>{{ dimensions().minAllowedBoatLength }}-{{ dimensions().maxAllowedBoatLength }}&nbsp;m</strong>
  </p>
  <p>
    <span class="i-arrow-y"></span>
    {{ "WIDTH" | translate }}:&nbsp;<strong
      >{{ dimensions().minAllowedBoatWidth }}-{{ dimensions().maxAllowedBoatWidth }}&nbsp;m</strong
    >
  </p>
  <p>
    <span class="i-arrow-depth"></span>
    {{ "DRAFT" | translate }}:&nbsp;<strong>{{ dimensions().depth }} m</strong>
  </p>
</div>
