import { ChangeDetectionStrategy, Component, ElementRef, inject, Input } from '@angular/core';

@Component({
  selector: 'dm-form-select-not-found',
  templateUrl: './select-not-found.component.html',
  styleUrl: './select-not-found.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectNotFoundComponent {
  elementRef: ElementRef<HTMLElement> = inject(ElementRef);
  @Input({ required: true }) search: string;
}
