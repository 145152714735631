import {
  BoatGangway,
  BoatLuxuryLevel,
  BoatMaterial,
  BoatMovementOperationForStatus,
  BoatType,
  BoatUsage,
  BookingPaymentStatus,
  ContractType,
  CrmOrderStatus,
  CrmReservationStatus,
  CrmState,
  Currency,
  CustomerType,
  BoatTypeCustomer,
  OrderPaymentStatus,
  PaymentProvider,
  PaymentStatusEnum,
  QuotationStatus,
} from '../backend';
import { IBoatDto, IResourceAvailabilityBoatMovement } from './boats.interface';
import { IDocument, IDocumentShort } from './file.interface';
import { IBaseId, Marina, MarinaProductType } from './marina.interface';
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap/timepicker/ngb-time-struct';
import { ExchangeRateResponse, IOnlineBookingBerthPriceDetails, IOnlineBookingResourceDetails } from './online-booking';
import { TPaymentChannel } from './payment.interface';
import { LOCALIZATION_LANGUAGES } from '../constants';
import { IUser } from './users.interface';
import { BillingMethod, MarinaProductUnit, OrderProductType } from './order.interface';
import { QuoteLeadSource } from './quotes.interface';

export enum ResourceBookingLabels {
  shortDeadline = 'short-deadline',
  overdue = 'overdue',
  noShow = 'no-show',
  needsRelocation = 'needs-relocation',
  needsCruiseVerification = 'needs-cruise-verification',
  quoteRenewalPlaceBlocker = 'quote-renewal-place-blocker',
  UNANNOUNCED_ARRIVAL = 'unannounced-arrival',
  OVERSTAYED = 'overstayed',
}

export interface BookingResource {
  resource: IOnlineBookingResourceDetails;
  pricingDetails: IOnlineBookingBerthPriceDetails;
}
export interface PostpaidBillingPeriodSummaryResponseDtoPostpaidBillingPeriodSummaryResponseDto {
  period: PeriodDto;
  summary: MediaUsageDto[];
}
export interface PostpaidBillingPeriodSummaryWithDetailed
  extends PostpaidBillingPeriodSummaryResponseDtoPostpaidBillingPeriodSummaryResponseDto {
  summary: Array<MediaUsageDto & { values?: DetailedMediaUsageDto[] }>;
}
export type MediaUsageUnit = 'kWh' | 'm3';

export interface DetailedMediaUsageForPeriodResponseDto {
  product: MediaUsageProduct;
  unit: MediaUsageUnit;
  values: DetailedMediaUsageDto[];
}
export interface PeriodDto {
  start: string;
  end: string;
}
export interface DetailedMediaUsageDto {
  date: string;
  quantity: number;
  pylonName: string;
  pylonCode: string;
  pylonChannel: string;
  amount: {
    currency: Currency;
    value: 0;
  };
}
export enum MediaUsageProduct {
  electricityUsage = 'ELECTRICITY_USAGE',
  waterUsage = 'WATER_USAGE',
}
export interface MediaUsageDto {
  product: MediaUsageProduct;
  quantity: number;
  unit: MediaUsageUnit;
  price: number;
  currency: Currency;
}

export type BookingResourceProduct = {
  product: MarinaProductType;
  productUnit?: number;
  isMandatory: boolean;
  totalPriceGross: number;
  vatRate: string;
  currency: Currency;
  productBillingType: string;
};

export interface ResourceBookingBody {
  fromDate: string;
  toDate: string;
  comments?: string;
  leadSource?: QuoteLeadSource;
  existingBoatId?: string;
  contractType?: ContractType;
  boatDimensions?: BoatDimensions;
  reservationStatus?: CrmReservationStatus | null;
  berth?: ResourceBookingBerth;
  berthId?: string;
  boatType?: BoatType;
  newBoat?: ResourceBookingBoat | null;
  customerId?: string | null;
  journalTaskBooking?: string;
  contractId?: string;
  resourceId?: string;
  quotationStatus?: QuotationStatus;
  quotationPrefilledData?: CustomerPrefilledData;
  ignoreSizeFit?: boolean;
  ignoreBerthAvailability?: boolean;
}

export interface ShortResourceBookingBody {
  id: string;
  fromDate: string;
  toDate: string;
  orderProductId: string;
  contractId: string;
  contractName?: string; //TODO: Confirm with backend
  humanReadableId: string;
  quotationStatus: string;
  crmStatus: CrmBookingStatus;
  source: string;
  boat?: Partial<IBoatDto>;
}

export interface IResourceBookingSearchPayload {
  fromDate?: string;
  toDate?: string;
  humanReadableId?: string;
  berth?: string;
  boatId?: string;
  customerId?: string;
  pier?: string;
  withoutCanceled?: boolean;
  contractType?: ContractType;
  quotationStatus?: QuotationStatus[];
  hasPayments?: boolean;
}

export type IResourceBookingForBoat = {
  marinaCode?: string;
  fromDate: string;
  toDate: string;
  boatWidth: number;
  boatLength: number;
  boatDraft?: number;
  boatType: BoatType;
  showAll?: boolean;
  includeUnavailableOnline?: boolean;
  limit?: number;
  offset?: number;
  searchInGaps?: boolean;
};

export interface CustomerCruiseDetailsDto {
  boatMovementId: string;
  returnDate: string;
  exitDate: string;
  expectedReturnDate: string;
  expectedExitDate: string;
}

export interface CustomerPrefilledData {
  city?: string;
  zip?: string;
  email: string;
  privateEmail?: string;
  businessEmail?: string;
  taxOffice?: string;
  language?: LOCALIZATION_LANGUAGES;
  countryCode?: string;
  countryId?: string;
  firstName?: string;
  lastName?: string;
  birthday?: string;
  nationality?: string;
  address?: string;
  state?: string;
  taxRegistrationType?: string;
  taxApplicabilityRule?: string;
  taxExemptNumber?: string;
  phone?: string;
  drivingLicense?: string;
  profession?: string;
  contactMethod?: string;
}

export interface ResourceBookingFindBerthForm {
  fromDate: string;
  toDate: string;
  existingBoatId?: string;
  boatType: BoatType;
  boatLength: number;
  boatWidth: number;
  boatDraft: number;
  marinaCode: string;
  searchInGaps: boolean;
}

export interface ResourceBookingQuickQuotationCustomerForm {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  type?: ResourceBookingQuotationAction; // Used for targeting proper action
  customerId?: string;
}

export interface ResourceBookingBerth {
  name: string;
  pier: string;
}

export interface ResourceBookingBoat {
  registrationNumber: string;
  name: string;
  brand: string;
  model?: string;
  productionYear?: number;
  length: number;
  maxBeam: number;
  draft: number;
  type: BoatType;
  material?: BoatMaterial;
  flag: string;
  portOfRegistration: string;
  usage?: BoatUsage;
  gangway?: BoatGangway;
  luxuryLevel?: BoatLuxuryLevel;
  isCharter?: boolean;
  charterLicenceNumber?: string;
  netTonnage?: number;
}

export type IResourceBookingRequest = {
  boatId?: string;
  contractType?: ContractType;
  activeOnDate?: string;
};

export type CpResourceBookingViewType = 'past' | 'upcoming' | 'ongoing';

export enum ResourceBookingPaymentStatus {
  PAID = 'paid',
  UNPAID = 'unpaid',
  EXPIRED = 'expired',
  UNKNOWN = 'unknown',
}

export interface ICreateResourceBooking {
  person?: {
    firstName: string;
    lastName: string;
    email: string;
    address: string;
    zip: string;
    country: string;
    phone: string;
  };
  fileIds?: string[];
  fromDate: string;
  toDate: string;
  comments?: string;
  boatId: string;
  resourceId?: string;
  marinaCode: string;
}

export type IResourceBookingBerthsListRequest = {
  marinaCode: string;
  boatId: string;
  fromDate: string;
  toDate: string;
  limit?: number;
  showAll?: boolean;
  offset?: number;
};

export interface IResourceBookingPaymentPayload {
  reservationNumber?: string;
  shopperLocale: string;
  channel: TPaymentChannel;
  paymentType: PaymentProvider;
}

export interface IResourceBookingOrderPaymentPayload extends Omit<IResourceBookingPaymentPayload, 'reservationNumber'> {
  orderId: string;
}

export type TResourceBookingPaymentMissingFieldsError = Array<keyof Partial<IUser>>;

export interface IResourceBookingPaymentRetryPayload {
  reservationNumber: string;
  failedPaymentId: string;
}

export interface IAdyenPaymentData {
  session: {
    sessionData: string;
    id: string;
    expiresAt: string;
  };
  clientKey: string;
}

export interface IIyziPayPaymentData {
  locale: 'tr' | 'en';
  checkoutFormContent: string;
  tokenExpireTime: number;
  paymentPageUrl: string;
  iframePaymentPageUrl: string;
}

export type TResourceBookingPaymentResponse = {
  referenceId: string;
  expiresAt: string | null;
  paymentType: PaymentProvider;
};

export type IResourceBookingPaymentResponse = IIyziPayPaymentResponse | IAdyenPaymentResponse;
export type IIyziPayPaymentResponse = IPaymentResponse<PaymentProvider.IYZIPAY, IIyziPayPaymentData>;
export type IAdyenPaymentResponse = IPaymentResponse<PaymentProvider.ADYEN, IAdyenPaymentData>;

export interface IPaymentResponse<Type, Data> {
  paymentType: Type;
  data: Data;
  referenceId: string;
  amount?: {
    value: number | null;
    currency: string | null;
  };
}

export interface IResourceBookingPaymentStatusPayload {
  referenceId: string;
}

export interface IResourceBookingPaymentStatusResponse {
  paymentId: string;
  status: IResourceBookingPaymentStatus;
  createdAt: string;
  failReason: string;
  type: PaymentProvider;
  amount: {
    currency: Currency;
    value: number;
  };
}

export interface IResourceBookingPaymentResendDTO {
  referenceId: string;
  type: 'booking';
  email?: string;
}

export interface IResourceBookingPaymentRedirectQuery {
  referenceId: string;
  // sessionId: string;
}

export interface IResourceBookingLastPaymentData {
  referenceId?: string;
  marina?: Marina;
}

export interface IResourceBookingQuotationsSearchPayload {
  pier?: string;
  berthId?: string;
  dateFrom?: string;
  dateTo?: string;
  boatId?: string;
  boatNameFragment?: string;
  customerId?: string;
  withoutCanceled?: boolean;
  contractType?: ContractType;
  quotationStatus?: QuotationStatus[];
  hasPayments?: boolean;
  creationDate?: CreationDates;
  modificationDate?: CreationDates;
  excludeQuoteDrafts?: boolean;
  labels?: ResourceBookingLabels[];
  ids?: string[];
}

export interface IResourceBookingBase {
  comments: string;
  contractId: string;
  contractName: string;
  contractType: ContractType;
  documents: IDocumentShort[];
  primaryOrder: IResourceBookingOrder;
  fromDate: string;
  humanReadableId: string;
  id: string;
  isApproved: boolean;
  quotationStatus: QuotationStatus;
  source: string;
  status: string;
  toDate: string;
  resource: IBaseId;
  createdAt: string;
  boatId: string;
  bookedProducts: ResourceBookingProduct[];
}

export type ResourceBookingProduct = {
  orderProductId: string;
  orderId: string;
  product: string;
  billingType: string;
  vatRate: number;
  totalPriceGross: number;
  unitPriceGross: number;
  totalPrice: number;
  unitPrice: number;
  vatAmount: number;
  isAlreadyPaid: boolean;
  productName: string;
  currency: Currency;
  isMandatory: boolean;
};

export interface ResourceBookingResponse {
  id: string;
  humanReadableId: string;
  fromDate: string;
  availableActions: BookingAvailableActions[];
  toDate: string;
  marina: string;
  source: string | null;
  resource: IBaseId | null;
  customer: ResourceBookingCustomer | null;
  order: IResourceBookingOrder | null;
  boat: IResourceBookingBoat | null;
  contractType: ContractType;
  updatedAt: string;
  documents: IDocument[];
  boatDimensions: IResourceBookingBoatDimensions;
  isApproved: boolean;
  status: CrmReservationStatus | null;
  contractId: string | null;
  comments: string | null;
  channel: string | null;
  quotationStatus: QuotationStatus;
  overlapsWith: string;
  quotationPrefilledData: CustomerPrefilledData | null;
  labels: string;
  bookedProducts: ResourceBookingProduct[];
}

export interface IResourceBookingBerthPriceDetails {
  unitPrice: number;
  unitPriceGross: number;
  sumPrice: number;
  sumPriceGross: number;
  duration: number;
  durationUnit: string;
  currency: Currency;
  exchangeRate: ExchangeRateResponse;
}

export interface IResourceBooking extends IResourceBookingBase {
  marinaCode: string;
  boatStatusInMarina: BoatMovementOperationForStatus;
}

export interface ICustomerResourceBooking extends IResourceBooking {
  availableActions: BookingAvailableActions[];
  paymentStatus: BookingPaymentStatus;
}

export type BookingAvailableActions =
  | 'CRUISE'
  | 'ARRIVAL'
  | 'DEPARTURE'
  | 'PAY'
  | 'COMMENTS'
  | 'RETURN'
  | 'SEE_OFFER'
  | 'COMPLETE_CHECK_IN'
  | 'MARK_AS_PAID'
  | 'COPY'
  | 'EDIT'
  | 'CONFIRM'
  | 'ENABLE_MEDIA_ACCESS'
  | 'SEND'
  | 'CHECK_IN_OFFLINE'
  | 'COMPLETE'
  | 'VERIFY_CRUISE'
  | 'CANCEL'
  | 'MOVE_BOAT';

export interface IResourceBookingCancellationPreview {
  html: string;
}

export interface IResourceBookingQuotation extends Omit<IResourceBookingBase, 'boatId'> {
  boat: IResourceBookingBoat;
  boatDimensions: IResourceBookingBoatDimensions;
  customer: ResourceBookingCustomer | null;
  hasWarnings: boolean;
  marina: string;
  payments: IResourceBookingPaymentInfo[] | null;
  labels: ResourceBookingLabels[];
  quotationPrefilledData?: CustomerPrefilledData;
  dashboardStatus: BookingDashboardStatus;
  orders: IResourceBookingOrder[];
  quoteId: string | null;
  ordersSummary: IResourceBookingOrdersSummary;
  availableActions: BookingAvailableActions[];
}

export interface IResourceBookingQuotationWithPrice extends IResourceBookingQuotation {
  paidAmount: number;
  unpaidAmount: number;
  currency: Currency;
}

export interface IResourceBookingOrdersSummary {
  orders: IOrdersSummaryOrder[];
  paidProductsSummary: IOrdersSummaryPaidProduct[];
  unpaidProductsSummary: IOrdersSummaryPaidProduct[];
}

export interface IOrdersSummaryOrder {
  id: string;
  startDate: string;
  endDate: string;
  state: CrmState;
  status: CrmOrderStatus;
  dashboardStatus: BookingDashboardStatus;
  paymentStatus: OrderPaymentStatus;
  paymentDate: string;
  isPrimary: boolean;
  associatedProducts: IOrdersSummaryOrderProduct[];
}

export interface IOrdersSummaryOrderProduct {
  orderProductId: string;
  product: OrderProductType;
  productName: string;
  productUnit: MarinaProductUnit;
  totalPriceGross: IPriceWithCurrency;
  unitPriceGross: IPriceWithCurrency;
  quantity: number;
  billingType: BillingMethod;
}

export interface IPriceWithCurrency {
  value: number;
  currency: Currency;
}

export interface IOrdersSummaryPaidProduct {
  product: OrderProductType;
  billingType: BillingMethod;
  productUnit: MarinaProductUnit;
  amount: IPriceWithCurrency;
  quantity: number;
}

export enum BookingDashboardStatus {
  UNPAID_SERVICE_BILLS = 'UNPAID_SERVICE_BILLS',
  INCOMPLETE = 'INCOMPLETE',
  DRAFT = 'DRAFT',
  SENT = 'SENT',
  PAID = 'PAID',
  CANCELLED = 'CANCELLED',
  CONFIRMED = 'CONFIRMED',
  CHECK_IN = 'CHECK_IN',
  CHECK_IN_REJECTED = 'CHECK_IN_REJECTED',
  CHECKED_IN = 'CHECKED_IN',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  BLOCKED = 'BLOCKED',
  UNANNOUNCED_ARRIVAL = 'UNANNOUNCED_ARRIVAL',
  CHECKED_OFFLINE = 'CHECKED_OFFLINE',
}

export interface IResourceBookingBoatDimensions {
  width: number;
  length: number;
}

export interface ResourceBookingCustomer {
  id: string;
  name: string;
  companyName: string;
  displayName: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  countryCode: string | null;
  type: CustomerType;
  phone: string | null;
}

export interface IResourceBookingPaymentInfo {
  id: string;
  marinaCode: string;
  type: IResourceBookingPaymentType;
  status: IResourceBookingPaymentStatus;
  amount: IResourceBookingPaymentAmount;
}

export enum IResourceBookingPaymentType {
  'Adyen',
  'Cash',
  'Other',
}

export enum IResourceBookingPaymentStatus {
  Pending = 'Pending',
  Success = 'Success',
  Failed = 'Failed',
  Expired = 'Expired',
}

export enum PostPaidResourceBookingPaymentStatus {
  UNPAID_SERVICES = 'UNPAID_SERVICES',
}

export interface IResourceBookingPaymentAmount {
  currency: Currency;
  value: number;
}

export interface IResourceBookingOrder {
  id: string;
  customerId: string | null;
  customer: ResourceBookingCustomer | null;
  invoiceCustomerId: string | null;
  totalPriceGross: IPriceWithCurrency;
  paymentStatus: OrderPaymentStatus;
  invoiceCustomer: ResourceBookingCustomer | null;
  isDraft: boolean;
}

export interface IResourceBookingBoat {
  id: string | null;
  existsInDatabase: boolean;
  name: string | null;
  length: number | null;
  draft: number;
  maxBeam: number | null;
  portOfRegistration: string;
  type: BoatType | null;
  statusInMarina: BoatMovementOperationForStatus | null;
  registrationNumber: string | null;
  brand: IBaseId | null;
  flag: string | null;
}

export enum ResourceBookingQuotationAction {
  CREATE = 'create',
  SEND = 'send',
}

export interface IAvailabilityCheck {
  pending?: boolean;
  berthAvailable: boolean | null;
  boatFit: boolean | null;
}

export interface IAvailabilityCheckPayload {
  resourceId: string;
  fromDate: string;
  toDate: string;
  boatId?: string;
  forBooking?: string;
  marinaCode?: string;
}

export interface IResourceBookingBerthAvailabilityBody {
  fromDate: string;
  toDate: string;
  boatId?: string;
  forBooking?: string;
}

export interface IResourceBookingBerthAvailabilityResponse {
  overlapsWith: ResourceBookingResponse[];
  overlappingBookings: OverlappingBooking[];
  boatFitsIn: BoatFitmentWithReasons;
  cruises: IResourceAvailabilityBoatMovement[];
}

export type BoatFitmentWithReasons = FitmentResponseWithReasons<BoatFitReason> | null;
export interface OverlappingBooking {
  bookingId: string;
  fitInGap: boolean;
  fromDate: string;
  gapEnd: string | null;
  gapStart: string | null;
  toDate: string;
}

export type FitmentResponseWithReasons<T> = {
  result: boolean;
  reasons?: T[];
};

export enum BoatFitReason {
  'TOO_LONG' = 'TOO_LONG',
  'TOO_SHORT' = 'TOO_SHORT',
  'TOO_WIDE' = 'TOO_WIDE',
  'TOO_NARROW' = 'TOO_NARROW',
  'TOO_DEEP' = 'TOO_DEEP',
  'UNKNOWN' = 'UNKNOWN',
}

export interface ResourceBookingBoatEventRequest {
  operation: BoatMovementOperationForStatus;
  operationTimestamp: string;
  declaredReturnDate?: string;
  allowsBerthReuse?: boolean;
}

export interface IResourceBookingInquiryPayload {
  fromDate: string;
  toDate: string;
  comments?: string;
  boatId: string;
  marinaCode: string;
}

export enum CreationDates {
  TODAY = 'TODAY',
  YESTERDAY = 'YESTERDAY',
  THIS_WEEK = 'THIS_WEEK',
  LAST_WEEK = 'LAST_WEEK',
  THIS_MONTH = 'THIS_MONTH',
  LAST_MONTH = 'LAST_MONTH',
}

export enum CrmBookingStatus {
  'Arrived' = 'Arrived',
  'Blocked' = 'Blocked',
  'Canceled' = 'Canceled',
  'Committed' = 'Committed',
  'Completed' = 'Completed',
  'In Progress' = 'In Progress',
  'No Show' = 'No Show',
  'On cruise' = 'On cruise',
  'Reserved' = 'Reserved',
  'Temporary assignment' = 'Temporary assignment',
}

export interface DateRange {
  fromDate: string;
  toDate: string;
}

export interface TimeRange<T = Partial<NgbTimeStruct>> {
  fromTime: T;
  toTime: T;
}

export interface IResourceBookingCancelPayload {
  customComment?: string;
  sendEmail: boolean;
}

export interface IResourceBookingCancelPreviewPayload {
  customComment?: string;
}

export enum ResourceBookingCancelReason {
  NO_AVAILABILITY = 'No availability',
  EXPIRED_OR_NO_PAYMENT = 'Expired / No payment',
  ENABLED = 'Enabled',
}

export interface BoatDimensions {
  length: number;
  width: number;
  draft: number;
}
export type BoatDimensionsWithType = BoatDimensions & {
  type: BoatTypeCustomer;
};
export interface IResourceBookingCalculatePricingParams {
  marinaCode?: string;
  resourceId: string;
  fromDate: string;
  toDate: string;
  isMultihull: boolean;
  boatId?: string;
  boatDimensions?: BoatDimensions;
}

export enum SelectionTypes {
  bigger = 'bigger',
  matching = 'matching',
  manual = 'manual',
}

export interface ISelectedResourceWithType {
  id: string;
  type: SelectionTypes;
}

export interface IResourceBookingCalculatePricingBoatData {
  id: string;
  type: BoatType;
  loa?: number;
  maxBeam?: number;
  draft?: number;
}

export type MmsResourceBookingAvailableProducts = {
  marinaProduct: MmsResourceBookingAvailableMediaAccessMarinaProduct;
  pricing: MmsResourceBookingAvailableMediaAccessPricing;
};

export type MmsResourceBookingMediaAccess = {
  existingMediaProducts: MmsResourceBookingAvailableProducts[];
  additionalMediaProducts: MmsResourceBookingAvailableProducts[];
  shouldCreateNewOrder: boolean;
};

export type MmsResourceBookingAvailableMediaAccessPricing = {
  unitPrice: number;
  unitPriceGross: number;
  sumPrice: number;
  sumPriceGross: number;
  duration: number;
  durationUnit: string;
  currency: Currency;
  vatRate: number;
  exchangeRate: {
    rateValue: number;
    rateDate: string;
    downloadTime: number;
  };
  pricelistId: string;
};

export type MmsResourceBookingAvailableMediaAccessMarinaProduct = {
  id: string;
  type: string;
  productNumber: string;
  billingType: string;
  status: string;
  name: string;
  description: string;
  productUnit: string;
};

export type MmsResourceBookingJournalTask = IResourceBookingQuotation & {
  paymentStatus: PaymentStatusEnum;
};
