import { ChangeDetectionStrategy, Component, effect, input, signal } from '@angular/core';
import { CardComponent } from './card.component';
import { toggleHeightAnimation } from '@dm-workspace/shared';

@Component({
  selector: 'dm-ui-card-expanded',
  templateUrl: `./card-expanded.component.html`,
  styleUrl: './card-expanded.component.scss',
  animations: [toggleHeightAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardExpandedComponent extends CardComponent {
  isOpen = input<boolean>(false);
  isPanelOpen = signal(false);
  constructor() {
    super();
    effect(() => this.isPanelOpen.set(this.isOpen()), { allowSignalWrites: true });
  }
}
