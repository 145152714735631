<ng-container *ngIf="pagination?.totalCount || collectionSize">
  <ngb-pagination
    [collectionSize]="pagination?.totalCount() || collectionSize"
    [pageSize]="pagination?.limit() || rows"
    [(page)]="currentRow"
    [maxSize]="maxPageButtons"
    [rotate]="true"
    [boundaryLinks]="true"
    (pageChange)="changePage($event)">
    <ng-template ngbPaginationPrevious><i class="i-dropdown"></i></ng-template>
    <ng-template ngbPaginationNext><i class="i-dropdown next"></i></ng-template>
  </ngb-pagination>
  @if(!hideSelectRowsDropdown) {
    <div class="items-section">
      <dm-form-group>
        <ng-select
          [ngModel]="pagination?.limit() || rows"
          [items]="rowsPerPageOptions"
          [clearable]="false"
          [searchable]="false"
          (change)="onRowsChange($event)"
        >
          <ng-template ng-label-tmp let-item="item"> {{ item }} rows</ng-template>
        </ng-select>
      </dm-form-group>
      <span>{{ "ITEMS PER PAGE" | translate }}</span>
    </div>
  }
</ng-container>
