import { inject, Injectable } from '@angular/core';
import {
  BoatAlertResolveReason,
  IBoatAlertByBoatParams,
  IBoatAlertResponseDto,
  IBoatAlertSensorParams,
  IRequestParamsWithPagination,
  MarkAlertPayload,
  PaginatedNormal,
} from '@dm-workspace/types';
import { ApiHttpClient, ApiService } from '../api/api-http-client';
import { BehaviorSubject, delay, first, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class S4bBoatAlertApiService extends ApiService {
  protected readonly apiHttpClient = inject(ApiHttpClient);
  protected readonly resourceUrl = this.apiHttpClient.createResourceUrl('/s4b/boat-alert');
  private resolveReasonsListSubject?: BehaviorSubject<BoatAlertResolveReason>;
  public getSensorAlert(sensorId: string, params?: IRequestParamsWithPagination<IBoatAlertSensorParams>) {
    return this.apiHttpClient.get<PaginatedNormal<IBoatAlertResponseDto>>(`${this.resourceUrl}/${sensorId}`, {
      params,
    });
  }

  public getActiveAlertsForBoat(boatId: string) {
    return this.apiHttpClient.get<IBoatAlertResponseDto[]>(`${this.resourceUrl}/active-alerts-for-boat/${boatId}`);
  }
  public getResolveReason() {
    if (this.resolveReasonsListSubject) {
      return this.resolveReasonsListSubject.asObservable().pipe(first());
    }
    return this.apiHttpClient
      .get<BoatAlertResolveReason>(`${this.resourceUrl}/resolve-reasons/list`)
      .pipe(tap((value) => (this.resolveReasonsListSubject = new BehaviorSubject(value))));
  }

  public markAlert(s4bAlertId: number, options: MarkAlertPayload) {
    return this.apiHttpClient.post<void>(`${this.resourceUrl}/${s4bAlertId}/process`, options);
  }
  public transferSubscription(subscriptionId: string, boatId: string) {
    return of(true).pipe(delay(1000));
    //TODO api integration
    return this.apiHttpClient.post(`${this.resourceUrl}/sensor-details/${subscriptionId}`, {
      boatId,
    });
  }
  updatePhone(phone: string, subscriptionId: string) {
    return this.apiHttpClient.patch(this.apiHttpClient.createResourceUrl('/s4b/mmi/subscription/' + subscriptionId), {
      phone,
    });
  }

  public getAlertsForBoat(boatId: string, params?: IRequestParamsWithPagination<IBoatAlertByBoatParams>) {
    return this.apiHttpClient.get<PaginatedNormal<IBoatAlertResponseDto>>(`${this.resourceUrl}/by-boat/${boatId}`, {
      params,
    });
  }
}
