<div class="d-flex justify-content-between align-items-center w-100 card-expanded-header">
  <div class="card-expanded-header__wrapper">
    <ng-content select="dm-ui-card-header" />
  </div>
  <div class="d-flex justify-content-end align-items-center">
    <button class="details-link" (click)="isPanelOpen.set(!isPanelOpen())">
      <span class="i-chevron-left" [class.is-open]="isPanelOpen()"></span>
      @if (isPanelOpen()) {
        {{ "SHOW_LESS" | translate }}
      } @else {
        {{ "SHOW_MORE" | translate }}
      }
    </button>
    <ng-content select="[dm-ui-card-header-action]" />
  </div>
</div>
<div [@toggleHeight]="isPanelOpen()">
  <ng-content select="dm-ui-card-body" />
</div>
<ng-content />
