export enum LOCALIZATION_LANGUAGES {
  ENGLISH = 'en-gb',
  // ENGLISH_GB = 'en-gb',
  ENGLISH_US = 'en-us',
  GERMAN = 'de',
  GREEK = 'el',
  TURKISH = 'tr',
  SPANISH = 'es',
  CROATIAN = 'hr',
  ITALIAN = 'it',
  FRENCH = 'fr',
}

export const SUPPORTED_LANGUAGES: LOCALIZATION_LANGUAGES[] = [
  LOCALIZATION_LANGUAGES.ENGLISH,
  LOCALIZATION_LANGUAGES.TURKISH,
  LOCALIZATION_LANGUAGES.SPANISH,
  LOCALIZATION_LANGUAGES.GERMAN,
  LOCALIZATION_LANGUAGES.ITALIAN,
  LOCALIZATION_LANGUAGES.CROATIAN,
  LOCALIZATION_LANGUAGES.GREEK,
  LOCALIZATION_LANGUAGES.FRENCH,
];
export const lngWithoutDialect = (lng: LOCALIZATION_LANGUAGES): string => {
  return lng.split('-')[0];
};
export const LANGUAGES_SUPPORTED_BY_D365 = Object.keys(LOCALIZATION_LANGUAGES).map((key: string) => ({
  name: `LANGUAGES.${key}`,
  code: LOCALIZATION_LANGUAGES[key as keyof typeof LOCALIZATION_LANGUAGES],
}));

export const HIDDEN_LANGUAGES = [LOCALIZATION_LANGUAGES.ENGLISH_US];

export const LANGUAGES_SUPPORTED_BY_D365_WITHOUT_HIDDEN = LANGUAGES_SUPPORTED_BY_D365.filter(
  (lang) => !HIDDEN_LANGUAGES.includes(lang.code)
);

export enum CountryCode {
  Spain = 'ES',
  Turkey = 'TR',
  Italy = 'IT',
  Greek = 'GR',
  Croat = 'HR',
}
