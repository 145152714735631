import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { BerthDimensions } from '@dm-workspace/types';

@Component({
  selector: 'dm-ui-berth-dimensions',
  templateUrl: './berth-dimension.component.html',
  styleUrls: ['./berth-dimension.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BerthDimensionComponent {
  dimensions = input.required<BerthDimensions>();
}
