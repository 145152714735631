import { PylonMediaType, PylonSocket } from '@dm-workspace/types';

export class PylonUtils {
  static icons: Record<PylonMediaType, string> = {
    [PylonMediaType.ELECTRICITY]: 'i-plug-in',
    [PylonMediaType.WATER]: 'i-drop',
  };
  static validateId(id: string): boolean {
    if (!id) {
      return false;
    }
    return /^\d+$/.test(id);
  }
  static groupSocketByMediaType(pylons: PylonSocket[]): Record<PylonMediaType, PylonSocket[]> {
    if (!pylons) {
      return null;
    }

    const uniqueSocketsMediaTypes = [...new Set(pylons.map((socket) => socket.mediaType))];
    const mediaTypesObj: Record<PylonMediaType, PylonSocket[]> = uniqueSocketsMediaTypes.reduce(
      (acc, mediaType) => {
        acc[mediaType] = [];
        return acc;
      },
      {} as Record<PylonMediaType, PylonSocket[]>
    );

    return pylons.reduce((acc, socket) => {
      acc[socket.mediaType].push(socket);
      return acc;
    }, mediaTypesObj);
  }
}
