<div class="row">
  <div class="col-6">
    <h5 class="header-12 color-secondary">{{ "GENERAL_INFO" | translate }}</h5>
    <dl class="description-list">
      <dt>{{ "BOAT_NAME" | translate }}</dt>
      <dd>{{ boat().name | empty }}</dd>
      <dt>{{ "TYPE" | translate }}</dt>
      <dd>{{ boat().type | transformToTranslateKey | translate | empty }}</dd>
      <dt>{{ "FLAG" | translate }}</dt>
      <dd><dm-ui-boat-flag [flag]="boat().flag" /></dd>
    </dl>
    <h5 class="header-12 color-secondary">{{ "DIMENSIONS" | translate }}</h5>
    <dl class="description-list">
      <dt>{{ "LOA" | translate }} (m)</dt>
      <dd>{{ boat().length }} m</dd>
      <dt>{{ "WIDTH" | translate }} (m)</dt>
      <dd>{{ boat().maxBeam }} m</dd>
      <dt>{{ "DRAFT" | translate }} (m)</dt>
      <dd>{{ boat().draft }} m</dd>
    </dl>
  </div>
  <div class="col-6">
    <h5 class="header-12 color-secondary">{{ "ADDITIONAL_DATA" | translate }}</h5>
    <dl class="description-list">
      <dt>{{ "PORT_OF_REGISTRATION" | translate }}</dt>
      <dd>{{ boat().portOfRegistration | empty }}</dd>
      <dt>{{ "REGISTRATION_NUMBER" | translate }}</dt>
      <dd>{{ boat().registrationNumber | empty }}</dd>
      <dt>{{ "BOAT_USAGE" | translate }}</dt>
      <dd>{{ boat().usage | empty }}</dd>
      <dt>{{ "BRAND" | translate }}</dt>
      <dd>{{ boat().brand.name | empty }}</dd>
      <dt>{{ "VSS" | translate }}</dt>
      <dd>{{ boat().vssNumber | empty }}</dd>
    </dl>
  </div>
</div>
