import { ChangeDetectionStrategy, Component, HostBinding, input } from '@angular/core';
import { IBoat } from '@dm-workspace/types';

@Component({
  selector: 'dm-ui-boat-info',
  templateUrl: './mms-ui-boat-info.component.html',
  styleUrl: './mms-ui-boat-info.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmsUiBoatInfoComponent {
  public boat = input.required<IBoat>();
  @HostBinding('class.black-text')
  public blackText = input<boolean>(false);
}
