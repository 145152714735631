import { CountryCode } from '../constants';

export enum MmsPlannerTaskType {
  BOAT_COUNTING = 'BoatCounting',
  BOAT_CARING = 'BoatCaring',
  /**
   * @deprecated
   */
  CHECKLIST = 'Checklist',
  AD_HOC = 'AdHoc',
  STANDARD = 'Standard',
  MARINA_BASED = 'MarinaBased',
  BOAT_ALERT = 'BoatAlert',
}

export enum PlannerTemplateInterval {
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  MONTHLY = 'Monthly',
  YEARLY = 'Yearly',
}

export enum PlannerTemplateStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export enum PlannerTaskStatus {
  TODO = 'ToDo',
  IN_PROGRESS = 'InProgress',
  PAUSED = 'Paused',
  COMPLETED = 'Completed',
  EXPIRED = 'Expired',
}

export enum MmsPlannerTaskItemPictureRequiredAnswer {
  YES = 'Yes',
  NO = 'No',
  ALL = 'All',
}

export enum MmsPlannerTaskItemAnswerType {
  CHOICE = 'Choice',
  CHECKBOX = 'Checkbox',
}

export const PlannerTaskItemNoteAnswer = {
  YES: 'Yes',
  NO: 'No',
  ALL: 'All',
} as const;
export type MmsPlannerTaskItemNoteRequiredAnswer =
  (typeof PlannerTaskItemNoteAnswer)[keyof typeof PlannerTaskItemNoteAnswer];

export enum PlannerTaskSubtaskAnswer {
  YES = 'Yes',
  NO = 'No',
  ALL = 'All',
}

export type TPlannerTemplatePierConfig = {
  id: string;
  name: string;
};

export type TGetPlannerTemplatesParams = {
  marinaCode: string;
  name?: string;
  type?: string;
  interval?: string;
  status?: string;
  taskGroup?: string;
};

export type PlannerTemplatesEditor = {
  id: string;
  name: string;
  marinas: Array<{
    marinaCode: string;
    name?: string;
    countryCode?: string;
  }>;
};

export type TGetPlannerTasksParams = {
  marinaCode: string;
  name?: string;
  type?: string;
  interval?: string;
  status?: string;
  taskGroup?: string;
  hasItemNotes?: boolean;
};

export type TEditPlannerTemplateBody = {
  name: string;
  type: MmsPlannerTaskType;
  taskGroups: string[];
  taskItems?: TEditPlannerTemplateTaskItem[];
  templateSchedules: TPlannerTemplateSchedule[];
  interval: PlannerTemplateInterval;
  pierConfig: string[];
  excludedBerths: string[];
  marinaCode?: string;
  templateSetId: string;
  taskCategory: PlannerTaskCategory;
  taskInternalType: PlannerTaskInternalType;
};

export type TEditPlannerTemplateTaskItem = {
  index: number;
  content: string;
  pictureRequired: boolean;
  numberOfPicturesRequired: number;
  pictureRequiredAnswer: MmsPlannerTaskItemPictureRequiredAnswer;
  answerType: MmsPlannerTaskItemAnswerType;
  noteRequired: boolean;
  noteRequiredAnswer: MmsPlannerTaskItemNoteRequiredAnswer;
};

export interface TPlannerTemplateEmployee {
  id: string;
  name: string;
  taskGroups: TPlannerTemplateTeam[];
  marinas: Array<Array<{ marinaCode: string }>>;
}

export type MmsPlannerTemplate = {
  id: string;
  name: string;
  taskItems: MmsPlannerTemplateTaskItem[];
  taskGroups: TPlannerTemplateTeam[];
  type: MmsPlannerTaskType;
  interval: PlannerTemplateInterval;
  status: PlannerTemplateStatus;
  updatedAt: string;
  pierConfig: TPlannerTemplatePierConfig[];
  editedBy?: TPlannerTemplateEmployee | null;
  templateSchedules: TPlannerTemplateSchedule[];
  marinaLocation: TPlannerMarinaLocation | null;
  excludedBerths: string[];
  taskCategory: PlannerTaskCategory;
  templateSet: TPlannerTemplateSet;
  taskInternalType: PlannerTaskInternalType;
};

export interface MmsPlannerTemplateTaskItem {
  index: number;
  content: string;
  fulfilled: boolean;
  pictureRequired: boolean;
  numberOfPicturesRequired: number;
  pictureRequiredAnswer: MmsPlannerTaskItemPictureRequiredAnswer;
  answerType: MmsPlannerTaskItemAnswerType;
  noteRequired: boolean;
  noteRequiredAnswer: MmsPlannerTaskItemNoteRequiredAnswer;
}

export type TPlannerTemplateSchedule = {
  due: string;
  prefix: string;
};

export enum TPlannerTaskStatus {
  TODO = 'ToDo',
  IN_PROGRESS = 'InProgress',
  PAUSED = 'Paused',
  COMPLETED = 'Completed',
  EXPIRED = 'Expired',
}

export type TPlannerTaskNote = {
  id: string;
  content: string;
};

export type TPlannerTaskSubtaskFile = {
  id: string;
  index: number;
  downloadUrl: string;
};

export type TPlannerTaskSubtaskItem = {
  content: string;
  fulfilled: boolean;
  pictureRequired: boolean;
  note: string;
  numberOfPicturesRequired: number;
  pictureRequiredAnswer: MmsPlannerTaskItemPictureRequiredAnswer;
  answer: PlannerTaskSubtaskAnswer;
  files: TPlannerTaskSubtaskFile[];
};

export type TPlannerTaskBoatCountingSingleProcessResult = {
  id: string;
  resource: {
    name: string;
  };
  /**
   * @deprecated use `actions` property
   */
  executedActions: Array<{
    name: string;
  }>;
  actions: Array<PlannerTaskBoatCountingSingleProcessResultAction>;
};

export type PlannerTaskBoatCountingSingleProcessResultAction = {
  action: string;
  result: string;
};
export enum TaskAction {
  MarkAsDone = 'MarkAsDone',
  Reset = 'Reset',
  Unassign = 'Unassign',
  Delete = 'Delete',
}
export type TPlannerTask = {
  id: string;
  title: string;
  status: PlannerTaskStatus;
  type: MmsPlannerTaskType;
  taskGroups: TPlannerTemplateTeam[];
  deadline: string;
  handledBy: TPlannerTaskUser;
  assignedBy: TPlannerTaskUser;
  availableActions: TaskAction[];
  isUrgent: boolean;
  note: string;
  images: TPlannerTaskSubtaskFile[];
  assignedTo: {
    groups: TPlannerTaskGroup[];
    users: TPlannerTaskUser[];
  };
  interval: PlannerTemplateInterval;
  location?: TPlannerMarinaLocation;
  updatedAt: string;
  taskItems?: TPlannerTaskSubtaskItem[] | null;
  taskNotes?: TPlannerTaskNote[] | null;
  taskTemplate?: MmsPlannerTemplate;
  startedAt: string | null;
  completedAt: string | null;
  customLocation?: string;
};

export type TPlannerTaskBoatCountingSingleReportBoat = {
  name: string;
};

export type TPlannerTaskBoatCountingSingleReport = {
  id: string;
  expectedBoat?: TPlannerTaskBoatCountingSingleReportBoat;
  reportedBoat?: TPlannerTaskBoatCountingSingleReportBoat;
  resourceName: string;
  marinaStatus: string;
  bookingType?: string;
  // TODO: missing
  bookingEndDate?: string;
};

export type PlannerTasksReportDto = {
  marinaCode?: CountryCode;
  dateFrom: string;
  dateTo: string;
};

export type TPlannerMarinaLocation = {
  id: string;
  name: string;
};

export type TPostPlannerMarinaLocation = {
  name: string;
  marinaCode: string;
};

export type TPlannerTaskUser = {
  id: string;
  name: string;
};

export type TPlannerTaskGroup = {
  id: string;
  groupName: string;
  users: TPlannerTaskUser[];
};

export type TPlannerTemplateTeam = {
  id: string;
  name: string;
  updateAt: string;
};

export type TPlannerTeam = {
  id: string;
  name: string;
  updatedAt: string;
  employeeCount: string;
};

export type TPlannerTeamSingle = TPlannerTeam & {
  employees: MmsPlannerTaskGroupEmployee[];
};

export type MmsPlannerTaskGroupEmployee = {
  id: string;
  email: string;
};

export type TEditPlannerTeamBody = {
  name: string;
  marinaCode: string;
  employeeIds: string[];
};

export enum PlannerTaskCategory {
  EQUIPMENT = 'Equipment',
  BOAT_COUNTING = 'Boat Counting',
  HEALTH_AND_SAFETY = 'Health and Safety',
  ENVIRONMENT_PROTECTION = 'Environment Protection',
  LIFE_SAVING = 'Life-Saving',
  OPERATION_TASKS = 'Operation Tasks',
  FIRE_FIGHTING = 'Fire fighting',
}

export enum PlannerTaskInternalType {
  INSPECTION = 'Inspection',
  MAINTENANCE = 'Maintenance',
  REPAIR = 'Repair',
  CUSTOMER_SERVICE = 'Customer Service',
  BOAT_COUNTING = 'Boat Counting',
}

export type TPlannerTemplateSet = {
  id: string;
  name: string;
  translationKey: string;
};
