import {
  BoatGangway,
  BoatLuxuryLevel,
  BoatMaintenanceLevel,
  BoatMaterial,
  BoatMovementOperation,
  BoatMovementOperationForStatus,
  BoatMovementStatus,
  BoatType,
  BoatUsage,
  ContactBoatRelationType,
  UserType,
} from '../backend';
import { BerthAvailabilityDto } from './berth.interface';
import { Contact } from './contact.interface';
import { IContract } from './contract.interface';
import { IBaseId } from './marina.interface';
import { Note } from './note.interface';
import { IBoatMovementResource } from './resource.interface';
import { ApiFile } from './file.interface';
import { TPlannerTaskSubtaskFile } from './planner.interface';
import { LOCALIZATION_LANGUAGES } from '../constants';
import { Customer } from './customer.interface';

export interface IBoatCreatePayload {
  brandId: string;
  charterLicenceNumber?: string | null;
  draft: number;
  flag: string; // enum - 2-digit ISO country code (eg: "PL")
  gangway?: BoatGangway | null;
  isCharter?: boolean | null;
  length: number;
  luxuryLevel?: BoatLuxuryLevel | null;
  material?: BoatMaterial | null;
  maxBeam?: number;
  modelId?: string | null;
  name: string;
  netTonnage?: number | null;
  portOfRegistration: string;
  productionYear?: number | null;
  registrationNumber: string; // KT-4443
  type: BoatType;
  usage?: BoatUsage;
}

export interface IBoat {
  id: string;
  insurance: IBoatInsurance;
  localName: string | null;
  maintenanceLevel: BoatMaintenanceLevel | null;
  relations: IBoatRelation[];
  brand: ISimpleBrandModel;
  charterLicenceNumber?: string | null;
  draft: number;
  flag: string; // enum - 2-digit ISO country code (eg: "PL")
  gangway?: BoatGangway | null;
  isCharter?: boolean | null;
  length: number;
  entitySource?: 'Online Booking';
  luxuryLevel?: BoatLuxuryLevel | null;
  material?: BoatMaterial | null;
  maxBeam: number;
  photo: ApiFile;
  preferredCustomer: Customer;
  model?: ISimpleBrandModel;
  name: string;
  netTonnage?: number | null;
  portOfRegistration: string;
  productionYear?: number | null;
  registrationNumber: string; // KT-4443
  type: BoatType;
  usage?: BoatUsage;
  vssNumber?: string;
}

export interface IBoatWithSubscriptions extends IBoat {
  sensorStatus: IAlertStatus;
  s4bSubscription: IBoatS4bSubscription | null;
}

export interface IBoatRelation {
  id: string;
  contactId: string;
  startDate: string;
  endDate: string;
  type: IBoatRelationType;
  note: string;
}

export enum IBoatRelationType {
  OWNER = 'Owner',
  CAPTAIN = 'Captain',
  AGENT = 'Agent',
  BROKER = 'Broker',
  CURRENT = 'Current',
  RELATED = 'Related',
  PORTAL = 'Portal', // This is charter
}

export interface IBoatDto extends IBoat {
  activeOrders: IContract[];
  notes: Note[];
  statusInMarina: BoatMovementOperationForStatus;
  vssNumber?: string;
  sensorStatus: IAlertStatus;
  s4bSubscription: IBoatS4bSubscription | null;
}

export type BoatsSearchResult = Array<IBoatDto & { isHighlighted: boolean }>;

export interface IBoatCaringReportDto {
  performedAt: string;
  performedBy: string;
  mooringLinesChecked: boolean;
  generalExteriorChecked: boolean;
  pedestalConnectionChecked: boolean;
  fendersPlacementChecked: boolean;
  files: TPlannerTaskSubtaskFile[];
}
export interface IBoatCaringReportParams {
  limit?: number;
  boatId: string;
  year?: string;
  marinaCode?: string;
}
export interface IBoatS4bSubscription {
  id: string;
  boatId: string;
  orderProductId: string;
  startDate: string;
  endDate: string;
  email: string;
  phone: string;
  customerName: string;
  language: string;
  boatType: string | null;
  boatModel: string | null;
  boatName: string | null;
  marinaType: string | null;
  marinaName: string | null;
  berthId: string | null;
  sensors: IBoatSensor[] | null;
}

export type IBoatSensorShort = Pick<IBoatSensor, 'id' | 'type'>;

export interface IBoatSensor {
  sensorId: string;
  sensorName: string;
  devEui: string;
  typeId: number;
  type: BoatSensorType;
  id: string;
  voltage: BoatSensorVoltage;
  rented: boolean;
  hasDownlinksReady: boolean;
  paired: boolean;
  active: boolean;
  reports_every_hours: number;
  connected_on: string;
  expires: string;
  next_billing_in_days: number;
  next_billing_soon: boolean;
  connection_active: boolean;
  measurements: BoatMeasurement[];
  notifications: IBoatSensorNotification[];
}

export enum BoatSensorType {
  BILGE = 'BILGE',
  HEAT = 'HEAT',
  SMOKE = 'SMOKE',
  BATTERY = 'BATTERY',
  UNKNOWN_SENSOR_TYPE = 'UNKNOWN_SENSOR_TYPE',
}

export enum BoatSensorVoltage {
  _12 = '12',
  _24 = '24',
}

export interface BoatMeasurement {
  status: number;
  type: BoatMeasurementType;
  value: string;
  unit: string;
  description: string;
  timestamp: string;
  signal: number;
}

export enum BoatMeasurementType {
  WATER_DETECTION = 'WATER_DETECTION',
  TEMPERATURE = 'TEMPERATURE',
  SMOKE_ALARM = 'SMOKE_ALARM',
  BATTERY = 'BATTERY',
  UNKNOWN = 'UNKNOWN',
}

export interface IBoatSensorNotification {
  type: IBoatSensorNotificationType;
  isActive: boolean;
}

export enum IBoatSensorNotificationType {
  FLOAT_IS_RAISED_WARN = 'FLOAT_IS_RAISED_WARN',
  FLOAT_IS_RAISED_FATAL = 'FLOAT_IS_RAISED_FATAL',
  RAPID_TEMPERATURE_RISE = 'RAPID_TEMPERATURE_RISE',
  EXTREME_TEMPERATURE = 'EXTREME_TEMPERATURE',
  HIGH_TEMPERATURE = 'HIGH_TEMPERATURE',
  LOW_TEMPERATURE = 'LOW_TEMPERATURE',
  SMOKE_DETECTED_WARN = 'SMOKE_DETECTED_WARN',
  SMOKE_DETECTED_FATAL = 'SMOKE_DETECTED_FATAL',
  CRITICAL_LOW_VOLTAGE = 'CRITICAL_LOW_VOLTAGE',
  LOW_BATTERY = 'LOW_BATTERY',
  INFO_FOR_BATTERY = 'INFO_FOR_BATTERY',
  CHARGING_START_VOLTAGE = 'CHARGING_START_VOLTAGE',
  CHARGING_END_VOLTAGE = 'CHARGING_END_VOLTAGE',
  HIGH_TEMPERATURE_FOR_BATTERY = 'HIGH_TEMPERATURE_FOR_BATTERY',
  UNKNOWN = 'UNKNOWN',
}

export enum IAlertStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  ALERT = 'ALERT',
}

export interface IBoatDetails {
  boatContacts: IBoatContact[];
  boat: IBoatWithSubscriptions & {
    lastKnownPosition: {
      resourceId: string;
      resourceName: string;
    };
  };
}

export interface IBoatContact {
  type: ContactBoatRelationType;
  note: string;
  contact: Contact;
  startDate: string;
  endDate: string;
}

export interface IContactBoatRelation {
  id: string;
  type: string;
  note: string;
  timePeriod: string;
  boat?: IBoat;
  contact: Contact;
}

export interface IFetchBoatParams {
  marina?: string;
  length?: number;
  search?: string;
  registrationNumber?: string;
  vssNumber?: string;
  boatType?: BoatType;
  ownership?: BoatUsage;
  flag?: string;
  boatSensorStatuses?: IAlertStatus;
  preferredCustomerId?: string;
}

export interface IBoatInsurance {
  expiryDate: string | null;
  agent: string | null;
  policyNumber: string | null;
}

export interface PostBoatMovementBody {
  gapStart?: string;
  gapEnd?: string;
  startsAt?: string;
  endsAt?: string;
  operation: BoatMovementOperation;
  sourceId?: string;
  destinationId?: string;
  marinaCode?: string;
  allowsBerthReuse?: boolean;
  transferBooking?: boolean;
  customerCruiseStatus?: CustomerCruiseStatus;
}

export interface MmsChangeMovementGapBody {
  gapStart: string;
  gapEnd: string;
}

export enum CustomerCruiseStatus {
  NEW = 'new',
  VERIFIED = 'verified',
}

export interface BoatReportOperationRequest {
  operationTimestamp: string; //$date-time
  declaredReturnDate?: string; //$date-time
  operation: BoatMovementOperationForStatus;
}

export interface IBoatMovementCustomerRequest {
  bookingId: string;
  declaredReturnDate?: string;
  operation: BoatMovementOperation;
}

export enum BoatMovementSource {
  none = 'none',
  berth = 'berth',
  land = 'land',
}
export interface IBoatMovement {
  movementOperationDate: string;
  id: string;
  boatId: string;
  operation: BoatMovementOperation;
  status: BoatMovementSource;
  returnDate: string;
  exitDate: string;
  expectedReturnDate: string;
  expectedExitDate: string;
  source: IBoatMovementResource;
  createdAt: string;
  updatedBy: IBoatMovementUpdatedBy;
  sourceType: BoatMovementStatus;
  destination: IBoatMovementResource;
  destinationType: BoatMovementSource;
  note: string;
  marinaCode: string;
  entitySource: string;
  allowsBerthReuse: boolean;
  modifiedById: boolean;
}
export interface IBoatMovementUpdatedBy {
  id: string;
  name: string;
  email: string;
  roleId: string;
  contactId: string;
  preferredLanguage: LOCALIZATION_LANGUAGES;
  isActive: boolean;
  type: UserType;
}
export interface IResourceAvailabilityBoatMovement
  extends Omit<IBoatMovement, 'id' | 'boatId' | 'status' | 'createdAt' | 'sourceType' | 'destinationType' | 'note'> {
  allowsBerthReuse: boolean;
}

// export interface BoatReport {
//   id: string;
//   boatId: string;
//   operation: BoatMovementOperationForStatus;
//   type: BoatMovementEventType;
//   operationTimestamp: string;
//   declaredReturnDate: string;
//   source: string;
//   sourceType: BoatReportSourceType;
//   destination: string;
//   destinationType: BoatReportSourceType;
// }

/**
 * @deprecated use BoatMovementOperation
 */
export enum BoatOperation {
  RETURN = 'return',
  DEPARTURE = 'departure',
  DRY_DOCK_LIFT = 'dry dock lift',
  DRY_DOCK_LAUNCH = 'dry dock launch',
  CRUISING = 'cruising',
  ARRIVAL = 'arrival',
  INTERNAL_RELOCATION_MOVEMENT = 'internal relocation movement',
}

export enum BoatReportSourceType {
  none = 'none',
  berth = 'berth',
  land = 'land',
}

export interface IBoatMovementPayload {
  boat: IBaseId;
  fromResourceId: string;
  berths?: BerthAvailabilityDto[];
  reservation?: IBoatMovementReservation;
}
export interface IBoatDepartureArrivalPayload {
  boatId: string;
  resourceId: string;
  berthName: string;
}

export interface ITransferBookingPayload {
  boatId: string;
  resource: IBaseId;
  reservationId: string;
  fromDate: string;
  toDate: string;
}
export interface IBoatAddMovementPayload {
  boat: IBaseId;
  resourceId: string;
  boatQuickReturnType?: BoatMovementOperation | null;
}
export interface IBoatMovementReservation {
  toDate: string;
  fromDate: string;
  reservationId: string;
}
export interface IBoatInsuranceUpdatePayload {
  expiryDate?: string;
  agent?: string;
  policyNumber?: string;
}

export interface IBoatBrandAndModelAutocompleteItem {
  value: string;
  details: {
    id: string;
  };
}

export interface ISimpleBrandModel {
  name: string;
  id: string;
}

export interface IBoatAlertSensorParams {
  sort: IBoatAlertSensorParamsSortEnum;
  statuses: IBoatAlertResponseDtoStatusEnum[];
}

export interface IBoatAlertByBoatParams extends IBoatAlertSensorParams {
  severity: IBoatAlertResponseDtoSeverityEnum;
  statuses: IBoatAlertResponseDtoStatusEnum[];
  sort: IBoatAlertSensorParamsSortEnum;
}

export enum IBoatAlertSensorParamsSortEnum {
  CreatedDateAsc = 'CREATED_DATE_ASC',
  CreatedDateDesc = 'CREATED_DATE_DESC',
}
export interface BoatAlertResolveReason {
  resolveReasons: ResolveReason[];
}

export interface ResolveReason {
  type: IBoatAlertResponseDtoTypeEnum;
  data: ResolveReasonData[];
}

export interface ResolveReasonData {
  causeId: string;
  resolveReason: string;
}

export interface IBoatAlertResponseDto {
  s4bAlertId: number;
  devEui: string;
  sensorId: string;
  resolveReason: string;
  boatId: string;
  status: IBoatAlertResponseDtoStatusEnum;
  severity: IBoatAlertResponseDtoSeverityEnum;
  createdDate: string;
  type: IBoatAlertResponseDtoTypeEnum;
  actionLogs: IBoatAlertActionLog[];
  id: string;
}

export interface IBoatAlertResponseWithType extends IBoatAlertResponseDto {
  sensorType: BoatSensorType;
}

export interface IBoatAlertActionLog {
  id: string;
  note: string;
  userId: string;
  boatAlertStatus: IBoatAlertResponseDtoStatusEnum;
  createdDate: string;
  files?: ApiFile[];
}

export enum IBoatAlertResponseDtoStatusEnum {
  Active = 'ACTIVE',
  InProgress = 'IN PROGRESS',
  Resolved = 'RESOLVED',
  Deleted = 'DELETED',
}

export enum IBoatAlertResponseDtoSeverityEnum {
  Warning = 'WARNING',
  Fatal = 'FATAL',
}

export enum IBoatAlertResponseDtoTypeEnum {
  FireDetected = 'FIRE_DETECTED',
  HeatOfRise = 'HEAT_OF_RISE',
  SevereHeat = 'SEVERE_HEAT',
  SmokeDetected = 'SMOKE_DETECTED',
  WaterIntrusion = 'WATER_INTRUSION',
  HighTemperature = 'HIGH_TEMPERATURE',
  LowTemperature = 'LOW_TEMPERATURE',
  BatteryLow = 'BATTERY_LOW',
  CriticalBatteryFault = 'CRITICAL_BATTERY_FAULT',
  BatteryOvervoltage = 'BATTERY_OVERVOLTAGE',
  InternalBatteryLow = 'INTERNAL_BATTERY_LOW',
  OutOfCommunication = 'OUT_OF_COMMUNICATION',
  BatteryOverheating = 'BATTERY_OVERHEATING',
  GeofenceExit = 'GEOFENCE_EXIT',
  GeofenceEntrance = 'GEOFENCE_ENTRANCE',
  DockingAssistance = 'DOCKING_ASSISTANCE',
  HeatThreshold3 = 'HEAT_THRESHOLD_3',
  HeatRateOfFailing = 'HEAT_RATE_OF_FAILING',
  FreezingPointBilgeAdvanced = 'FREEZING_POINT_BILGE_ADVANCED',
  UnknownAlertType = 'UNKNOWN_ALERT_TYPE',
}

export interface IBoatConnectSensorRequestDto {
  sensorId: string;
  sensorName: string;
  voltage: BoatSensorVoltage;
}

export interface SensorResponseDto {
  id: string;
  status: SensorInfoResponseDtoStatusEnum;
  s4bSubscriptionId: string;
  s4bSensorId: string | null;
  devEui: string;
  type: BoatSensorType;
  typeCode: string | null;
  typeName: string | null;
  typeDescription: string | null;
  typeId: number | null;
  sensorName: string;
  deletedAt: string | null;
}

export interface SensorInfoResponseDto {
  sensorId: string;
  sensorName: string;
  devEui: string;
  typeId: number;
  type: BoatSensorType;
  typeCode: string;
  typeName: string;
  typeDescription: string;
  imageUrl: string;
  status: SensorInfoResponseDtoStatusEnum;
  paired: boolean;
}

export enum SensorInfoResponseDtoStatusEnum {
  _1 = '1',
  _2 = '2',
}

export interface MarkAlertPayload {
  alertStatus: IBoatAlertResponseDtoStatusEnum;
  files?: string[];
  note?: string;
  alertResolveReason?: string;
}

export type AlertsByType = {
  [key in BoatSensorType]?: AlertsTabContent;
};

export type AlertsTabContent = { count: number; alerts: IBoatAlertResponseWithType[] };
