import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComponent } from './card.component';
import { CardHeaderComponent } from './card-header.component';
import { CardBodyDirective } from './card-body.component';
import { CardFooterDirective } from './card-footer.component';
import { CardExpandedComponent } from './card-expanded.component';
import { TranslateModule } from '@ngx-translate/core';

const components = [CardComponent, CardExpandedComponent, CardHeaderComponent, CardBodyDirective, CardFooterDirective];
@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: components,
  exports: components,
})
export class CardModule {}
