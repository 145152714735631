import { IPier } from './pier.interface';
import { CountryCode } from '../constants';
import { Currency, UOM } from '../backend';
import { OrderProductType } from './order.interface';

export interface IMarinaLayout {
  center: PolygonPoint;
  coordinates: PolygonPoint[][];
}

export interface IBaseId {
  id: string;
  name: string;
}

export interface CmsAttributes<T> {
  id: number;
  attributes: T;
}
export type MarinaCMS = CmsAttributes<{
  code: string;
  createdAt: string;
  locale: string;
  marinaName: string;
  publishedAt: string;
  contact: {
    image: {
      data: CmsAttributes<MarinaCMSMediaFile>;
    };
  };
  slug: string;
  updatedAt: string;
  googleReviews: SharedGoogleReviewsComponent;
  berthSettings: BerthSettingsCMS;
  // mediaThumbnails: MarinaCMSMediaThumbnails;
  marinaHeader: {
    keyFeatures: MarinaCMSKeyFeature[];
  };
}>;
export interface MarinaCMSKeyFeature {
  id: string;
  description: string;
}
export type MarinaCMSAnnualBenefits = CmsAttributes<{
  title: string;
  imageLocation: string;
  description: MarinaCMSAnnualBenefitsDescription[];
  image: {
    data: MarinaCMSAnnualBenefitsImageData[];
  };
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  locale: string;
  productCode: string;
}>;

export type MarinaCMSAnnualBenefitsImageData = CmsAttributes<{
  name: string;
  alternativeText: string;
  caption: string;
  width: number;
  height: number;
  formats: MarinaCMSMediaFileFormats;
  hash: string;
  ext: string;
  mime: string;
  size: number;
  url: string;
  previewUrl: string;
  provider: string;
  provider_metadata: string;
  createdAt: string;
  updatedAt: string;
  placeholder: string;
}>;
export interface MarinaCMSAnnualBenefitsDescription {
  type: MarinaCMSAnnualBenefitsType;
  children: {
    type: MarinaCMSAnnualBenefitsType;
    text: string;
    children: {
      type: 'text';
      text: string;
    }[];
  }[];
  format?: string;
  level?: number;
}
export type MarinaCMSAnnualBenefitsType = 'list' | 'list-item' | 'paragraph' | 'heading';
export interface SharedGoogleReviewsComponent {
  id: number;
  googleReviewId: string;
  url: string;
  user_ratings_total: number;
  rating: number;
}
export interface MarinaCMSMedia {
  id: number;
  file: {
    data: {
      id: number;
      attributes: MarinaCMSMediaFile;
    };
  };
}
export interface MarinaCMSMediaFile {
  name: string;
  alternativeText?: string;
  caption: string;
  width: number;
  height: number;
  formats: MarinaCMSMediaFileFormats;
  hash: string;
  ext: string;
  mime: string;
  size: number;
  url: string;
  previewUrl: string;
  provider: string;
  provider_metadata: string;
  createdAt: string;
  updatedAt: string;
  placeholder: string;
}
export interface MarinaCMSMediaFileFormat {
  ext: string;
  url: string;
  hash: string;
  mime: string;
  name: string;
  path: string;
  size: number;
  width: number;
  height: number;
  sizeInBytes: number;
}
export interface MarinaCMSMediaFileFormats {
  small: MarinaCMSMediaFileFormat;
  medium: MarinaCMSMediaFileFormat;
  thumbnail: MarinaCMSMediaFileFormat;
}
export interface MarinaCMSMediaThumbnails {
  id: number;
  images: MarinaCMSMedia[];
}
export type CMSSelectedMarina = {
  marinaCMS: MarinaCMS;
  marinaOB: Marina;
};
export interface BerthSettingsCMS {
  id: number;
  maxBoatDraft: number;
  maxBoatLength: number;
  maxBoatWidth: number;
}
export interface MarinaAdditionalServices {
  isEnabled: boolean;
  isMandatory: true;
  product: OrderProductType;
}
export interface Marina {
  id: string;
  code: string;
  name: string;
  country: string;
  pageSlug: string;
  countryCode: CountryCode;
  logo: {
    base64: string;
  };
  layout?: IMarinaLayout;
  imageUrl?: string | null;
  onlineBookingEnabled?: boolean;
  isExternalProcessingEnabled: boolean;
  additionalProperties?: MarinaAdditionalProperties;
  additionalServices?: MarinaAdditionalServices[];
  availableProducts?: MarinaAdditionalServices[];
}
export type MmiMarinaDto = Marina & {
  payments: {
    adyenMerchantAccount: string;
    wireTransferInfo: WireTransferDetailsDto;
  };
};
export type WireTransferDetailsDto = {
  bankName: string;
  recipientName: string;
  recipientAddress: string;
  iban: string;
  swiftCode: string;
};
export type MarinaTerms = {
  platform: string;
  privacyPolicy: string;
  sensors: string;
  services: string;
  servicesRenewals?: string;
  vexationClausesRenewals?: string | null;
  vexationClauses: string | null;
  website: string;
  withdrawalTermRenewals: string;
};

export type MarinaAdditionalProperties = {
  timezone?: string;
  manager?: string;
  bookingMaxDate?: string;
  isNewEnquiryProcessEnabled: boolean;
  contactInfo: IMarinaContactInfo;
  isPylonsFeatureEnabled: boolean;
  terms: MarinaTerms;
  paymentCurrency: Currency;
};
export type MarnaAdditionalPropertiesDto = {
  manager: string;
  salesManager: string;
  timezone: string;
  dmarinMarinaId: string;
  bookingMaxDate: string;
  contactInfo: IMarinaContactInfo;
  wireTransferDetails: WireTransferDetailsDto;
  paymentCurrency: Currency;
  welcomeLetterPdfUrl: string;
  isEmailEnabled: boolean;
  adyenMerchantAccount: string;
  terms: MarinaTerms;
  isSensorsCorporateSetupEnabled: boolean;
  isPostpaidCorporateSetupEnabled: boolean;
  isOfferOnlinePaymentEnabled: boolean;
};
export interface MarinaProduct {
  id: string;
  name: string;
  description: string;
  type: string;
  currencyCode: Currency;
  billingType: string;
  productNumber: string;
  status?: unknown;
  productUnit?: UOM;
}

export type MarinaProductType = 'MOORING' | 'SERVICE_FEES' | 'ELECTRICITY' | 'WATER' | 'ELECTRICITY_AND_WATER';

export interface MarinaProductDto {
  type: MarinaProductType;
  quantity: number;
}

export interface IMarinaContactInfo {
  address: string;
  email: string;
  fax: string;
  phone: string;
}

export interface IMarinaSelectable extends Marina {
  disabled: boolean;
}

export interface IMarinaCalendarFilters {
  berth?: string;
  pier?: string;
  humanReadableId?: string;
  fromDate: string;
  toDate: string;
  period: CalendarPeriods;
  berthLength?: number;
  berthWidth?: number;
  onlyFree?: boolean;
}

export enum CalendarPeriods {
  month = 'MONTH',
  week = 'WEEK',
}

export interface IMarinaCalendarDefaultFilters extends Omit<IMarinaCalendarFilters, 'fromDate' | 'toDate'> {
  fromDate: string;
  toDate: string;
}

export interface IMarinaWithPiers extends Marina {
  piers: IPier[];
}

type ILng = number;
type ILat = number;
export type PolygonPoint = [ILng, ILat];

export type IMapTransformationResponse = Record<string, PolygonPoint[]>;

export type MarinasNearbyFacilities = {
  facilitiesList: MarinasNearbyFacility[];
  linkToNearbyFacilities: {
    restaurants: string;
  };
  linkToMarina: string;
};

interface MarinasNearbyFacility {
  business_status: string;
  geometry: MarinasNearbyFacilityGeometry;
  icon: string;
  icon_background_color: string;
  icon_mask_base_uri: string;
  name: string;
  opening_hours: {
    open_now: boolean;
  };
  photos: MarinasNearbyFacilityPhoto[];
  place_id: string;
  plus_code: {
    compound_code: string;
    global_code: string;
  };
  rating: number;
  reference: string;
  scope: string;
  types: string[];
  user_ratings_total: number;
  vicinity: string;
}

interface MarinasNearbyFacilityPhoto {
  height: number;
  html_attributions: string[];
  photo_reference: string;
  width: number;
}

interface MarinasNearbyFacilityGeometry {
  location: MarinasNearbyFacilityGeometryLocation;
  viewport: {
    northeast: MarinasNearbyFacilityGeometryLocation;
    southwest: MarinasNearbyFacilityGeometryLocation;
  };
}

interface MarinasNearbyFacilityGeometryLocation {
  lat: number;
  lng: number;
}
