import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Pagination } from './pagination';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { distinctUntilChanged, filter, map } from 'rxjs';

@Component({
  selector: 'dm-ui-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationComponent implements OnInit {
  #destroyRef = inject(DestroyRef);
  #aRoute = inject(ActivatedRoute);
  #router = inject(Router);
  @Input() hideSelectRowsDropdown?: boolean = false;
  @Input() pagination?: Pagination;
  /**
   * @deprecated Use `pagination` instead
   */
  @Input() rows = 5;
  /**
   * @deprecated Use `pagination` instead
   */
  @Input() collectionSize = 5;
  /**
   * @deprecated Use `pagination` instead
   */
  @Input() maxPageButtons = 3;
  /**
   * @deprecated Use `pagination` instead
   */
  @Input() currentRow = 1;
  @Output() pageChange = new EventEmitter<number>();
  @Output() numberOfRowsChange = new EventEmitter<number>();
  public rowsPerPageOptions = [5, 10, 15, 20];

  ngOnInit() {
    this.#aRoute.queryParams
      .pipe(
        takeUntilDestroyed(this.#destroyRef),
        map((params) => parseInt(params['page'])),
        distinctUntilChanged(),
        filter((currentPage) => {
          if (!currentPage) {
            return;
          }
          return currentPage !== this.currentRow;
        })
      )
      .subscribe((currentPage) => {
        this.currentRow = currentPage;
        this.changePage(currentPage);
      });
  }

  onRowsChange($event: number): void {
    this.pagination?.changeLimit($event);
    this.currentRow = 1;
    this.numberOfRowsChange.emit($event);
  }

  changePage($event: number): void {
    this.pagination?.changePage($event);
    this.pageChange.emit($event);

    setTimeout(() => {
      this.#router.navigate([], {
        relativeTo: this.#aRoute,
        queryParams: {
          page: $event,
        },
        queryParamsHandling: 'merge',
      });
    });
  }
}
