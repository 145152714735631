import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

const COUNTRY_MAPPER: Record<string, string> = {
  EL: 'GR',
};
@Pipe({
  name: 'flag',
})
export class FlagPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(countryCode: string, size = 80): unknown {
    if (!countryCode) {
      return '';
    }

    return this.sanitizer.bypassSecurityTrustUrl(
      `https://flagcdn.com/w${size}/${(COUNTRY_MAPPER[countryCode] ?? countryCode).toLowerCase()}.png`
    );
  }
}
