import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  API_PREFIX,
  ApiPrefix,
  IBoatConnectSensorRequestDto,
  IBoatCreatePayload,
  IBoatDto,
  IBoatSensor,
  SensorInfoResponseDto,
  SensorResponseDto,
} from '@dm-workspace/types';
import { ApiClientService } from '../api/api-client.service';
import { ApiCRUDResource } from '../classes/api-resource.class';

@Injectable({
  providedIn: 'root',
})
export class S4bSubscriptionApiService extends ApiCRUDResource<IBoatDto, IBoatDto, IBoatCreatePayload> {
  protected get resourceUrl() {
    switch (this.apiPrefix) {
      case ApiPrefix.customerPanel:
      case ApiPrefix.mmsPanel: {
        return '/s4b/subscription';
      }
    }
  }

  constructor(
    @Inject(API_PREFIX) private apiPrefix: ApiPrefix,
    apiClientService: ApiClientService
  ) {
    super(apiClientService);
  }

  public connectSensor(subscriptionId: string, params: IBoatConnectSensorRequestDto): Observable<SensorResponseDto> {
    return this.apiClientService.post(`${this.resourceUrl}/${subscriptionId}/connect-sensor`, params);
  }
  public removeSensor(subscriptionId: string, sensorId: string): Observable<void> {
    return this.apiClientService.delete(`${this.resourceUrl}/${subscriptionId}/remove-sensor`, { body: { sensorId } });
  }

  public getSensorDetails(sensorId: string): Observable<IBoatSensor> {
    return this.apiClientService.get(`${this.resourceUrl}/sensor-details/${sensorId}`);
  }

  public getSensorInfo(devEuiCode: string): Observable<SensorInfoResponseDto> {
    return this.apiClientService.get(`${this.resourceUrl}/sensor-info/${devEuiCode}`);
  }
}
